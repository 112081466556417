import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import useOutsideClick from "../../hooks/useOutsideClick";

import {
  Wrapper,
  Initials,
  DropDownIndicator,
  DropDownWrapper,
  Menu,
  MenuItem,
  MenuLink,
  MenuItemIcon,
  MenuItemTitle,
  ProfileHighlight,
  AvatarImage,
  Details,
  ProfileName,
  ProfileFooter,
  Footer
} from "./style";

const AvatarDropDown = ({ items, avatar, username, company, logout }) => {
  const [dropdown, setDropdown] = useState(false);

  const wrapperRef = useRef();

  useOutsideClick(wrapperRef, () => {
    setDropdown(false);
  });

  function getInitials(name) {
    const names = name ? name.split(" ") : "";

    if (!names) return;

    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    } else {
      initials = name.substring(0, 2).toUpperCase();
    }

    return initials;
  }

  const handleIconClick = (e) => {
    e.preventDefault();
    setDropdown((prevState) => !prevState);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Initials onClick={handleIconClick}>{getInitials(username)}</Initials>

      <DropDownIndicator onClick={handleIconClick}>
        {dropdown ? "▲" : "▼"}
      </DropDownIndicator>
      <DropDownWrapper isActive={dropdown}>
        <Menu>
          <ProfileHighlight>
            <AvatarImage
              alt="profile-img"
              src={avatar}
              width="36"
              height="36"
            />
            <Details>
              <ProfileName>{username}</ProfileName>
              {company && <ProfileFooter>{company}</ProfileFooter>}
            </Details>
          </ProfileHighlight>
          {items.map((item, i) => (
            <MenuItem
              key={`AvatarDropDown${item.title}${i}`}
              onClick={item.action ? item.action : () => {}}
            >
              <MenuLink>
                <MenuItemIcon
                  src={item.icon}
                  alt="item.alt"
                  width="20"
                  height="20"
                />
                <MenuItemTitle>{item.title}</MenuItemTitle>
              </MenuLink>
            </MenuItem>
          ))}
          <Footer>
            <MenuItem>
              <MenuLink color="#F44336" onClick={logout}>
                Logout
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink>Settings</MenuLink>
            </MenuItem>
          </Footer>
        </Menu>
      </DropDownWrapper>
    </Wrapper>
  );
};

AvatarDropDown.propTypes = {
  items: PropTypes.array.isRequired,
  avatar: PropTypes.string,
  username: PropTypes.string,
  company: PropTypes.string,
  logout: PropTypes.func.isRequired
};

AvatarDropDown.defaultProps = {
  avatar: "",
  company: "",
  username: "___"
};

export default AvatarDropDown;
