import React from "react";
import PropTypes from "prop-types";

// import { ReactComponent as PlusSVG } from "../../assets/svg/plus.svg";
// import { ReactComponent as ClockSVG } from "../../assets/svg/clock.svg";
// import { ReactComponent as InsightsSVG } from "../../assets/svg/insights.svg";
import { ReactComponent as HomeSVG } from "../../assets/svg/Home.svg";
import { ReactComponent as GroupsSVG } from "../../assets/svg/groups.svg";
import { ReactComponent as UsersSVG } from "../../assets/svg/users.svg";
import { ReactComponent as MindfulnessSVG } from "../../assets/svg/mindfulness.svg";
import { ReactComponent as ReflectionsSVG } from "../../assets/svg/reflections.svg";
import { ReactComponent as CheckInsSVG } from "../../assets/svg/checkIns.svg";

import { SlideBody, MenuItem } from "./styles";

const SlideMenu = ({ goTo, isOpen, activePage }) => {
  const svgDefaultStyles = {
    marginRight: "10px",
    width: "1em",
    height: "1em"
  };

  return (
    <SlideBody isOpen={isOpen}>
      {/* <MenuItem
        onClick={() => {
          goTo('checkin');
        }}
        isActive={activePage === 'checkin'}
      >
        <PlusSVG style={{ ...svgDefaultStyles, width: '0.75em' }} />
        Add Check in
      </MenuItem>
      <MenuItem
        onClick={() => {
          goTo('history');
        }}
        isActive={activePage === 'history'}
      >
        <ClockSVG style={svgDefaultStyles} />
        My History
      </MenuItem>
      <MenuItem
        onClick={() => {
          goTo('insights');
        }}
        isActive={activePage === 'insights'}
      >
        <InsightsSVG style={svgDefaultStyles} />
        Insights
      </MenuItem> */}

      <MenuItem
        onClick={() => {
          goTo("dashboard");
        }}
        isActive={activePage === "dashboard"}
      >
        <HomeSVG style={svgDefaultStyles} />
        Dashboard
      </MenuItem>

      <MenuItem
        onClick={() => {
          goTo("groups");
        }}
        isActive={activePage === "groups"}
      >
        <GroupsSVG style={svgDefaultStyles} />
        Groups
      </MenuItem>

      <MenuItem
        onClick={() => {
          goTo("users");
        }}
        isActive={activePage === "users"}
      >
        <UsersSVG style={svgDefaultStyles} />
        Users
      </MenuItem>

      <MenuItem
        onClick={() => {
          goTo("mindfulness");
        }}
        isActive={activePage === "mindfulness"}
      >
        <MindfulnessSVG style={svgDefaultStyles} />
        Mindfulness
      </MenuItem>

      <MenuItem
        onClick={() => {
          goTo("reflections");
        }}
        isActive={activePage === "reflections"}
      >
        <ReflectionsSVG style={svgDefaultStyles} />
        Reflections
      </MenuItem>

      <MenuItem
        onClick={() => {
          goTo("checkIns");
        }}
        isActive={activePage === "checkIns"}
      >
        <CheckInsSVG style={svgDefaultStyles} />
        Check Ins
      </MenuItem>
    </SlideBody>
  );
};

SlideMenu.propTypes = {
  goTo: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  activePage: PropTypes.string.isRequired
};

export default SlideMenu;
