import { ApolloClient } from "@apollo/client";
import { createLink } from "./link";
import { createCache } from "./cache";

export const getClient = () => {
  const apolloClientConfig = {
    link: createLink(),
    cache: createCache(),
    name: "checkingin-web-apollo"
  };

  return new ApolloClient(apolloClientConfig);
};
