import React, { useEffect, useState } from "react";
import { Card, CardDeck, Container } from "react-bootstrap";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { START_DATE } from "react-nice-dates";
import { MainContainer } from "./style";
import EngagementTable from "../../components/EngamentTable";
import SummaryPanel from "../../components/SummaryPanel/index";
import AvgDailyEnergyBarCard from "../../components/AvgDailyEnergyBarCard/index";
import TimeCheckInsPieCard from "../../components/TimeCheckInsPieCard/index";
import WordCatBarGraph from "../../components/WordCatBarGraph/index";
import WordCloudGraph from "../../components/WordCloudGraph/index";

import "semantic-ui-css/semantic.min.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import DateFilterBar from "../../components/DateFilterBar";
import DateJson from "./result.json";

const DEFAULT_VALUE = 1;
// 05-09-2021 for now
// It would change to new Date() in the future.
const initialDate = new Date("2021-05-09T08:00:00.000Z");
const MAX_DATE = new Date("2021-05-09T08:00:00.000Z");
const MIN_DATE = new Date("2018-05-06T08:00:00.000Z");
// a list of dates data
const dates = DateJson.data;

const userHeaders = [
  "Name",
  "Email",
  "Check Ins",
  "Mindfulness",
  "Reflections",
  "Total Actions"
];
const userTestData = {
  entities: [
    {
      name: "Joe Mouse",
      email: "sequin@verizon.net",
      checkins: 23,
      mindfulness: 7,
      reflections: 6
    }
  ]
};

const companyHeaders = [
  "Company Name",
  "Check Ins",
  "Mindfulness",
  "Reflections",
  "Total Actions"
];

const companyTestData = {
  entities: [
    {
      name: "Google",
      checkins: 34,
      mindfulness: 4,
      reflections: 6,
      totalactions: 8
    }
  ]
};

const Dashboard = () => {
  // TODO: Attach all of variables here
  const [filter, setFilter] = useState("All time");

  // Summary Variables
  const [totalCheckins, setTotalCheckins] = useState(DEFAULT_VALUE);
  const [checkinsDiff, setCheckinsDiff] = useState(DEFAULT_VALUE);

  const [totalMindfulness, setTotalMindfulness] = useState(DEFAULT_VALUE);
  const [mindfulnessDiff, setMindfulnessDiff] = useState(DEFAULT_VALUE);

  const [totalReflections, setReflections] = useState(DEFAULT_VALUE);
  const [reflectionsDiff, setReflectionsDiff] = useState(DEFAULT_VALUE);

  // Most Engaged Users Variables
  const [userEngTable, setUserEngTable] = useState(userTestData.entities);

  // Company Engagement Variables
  const [compEngTable, setCompEngTable] = useState(companyTestData.entities);

  // User Summary Variables
  const [totalUsers, setTotalUsers] = useState(DEFAULT_VALUE);
  const [totalUsersDiff, setTotalUsersDiff] = useState(DEFAULT_VALUE);

  const [newUsers, setNewUsers] = useState(DEFAULT_VALUE);
  const [newUsersDiff, setNewUsersDiff] = useState(DEFAULT_VALUE);

  const [returningUsers, setReturningUsers] = useState(DEFAULT_VALUE);
  const [returningUsersDiff, setReturningUsersDiff] = useState(DEFAULT_VALUE);

  const [paidUsers, setPaidUsers] = useState(DEFAULT_VALUE);
  const [paidUsersDiff, setPaidUsersDiff] = useState(DEFAULT_VALUE);

  const [freeUsers, setFreeUsers] = useState(DEFAULT_VALUE);
  const [freeUsersDiff, setFreeUsersDiff] = useState(DEFAULT_VALUE);

  const [enterpriseUsers, setEnterpriseUsers] = useState(DEFAULT_VALUE);
  const [enterpriseUsersDiff, setEnterpriseUsersDiff] = useState(DEFAULT_VALUE);

  // popup window
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const formatDate = (targetDate) =>
    format(targetDate, "MMM dd, yyyy", { locale: enGB });

  // date picker
  const [startDate, setStartDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(initialDate);
  const [focus, setFocus] = useState(START_DATE);
  const start = startDate ? formatDate(startDate) : "None";
  const end = endDate ? formatDate(endDate) : "None";

  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };

  const calculateDate = (currentDate, minusDay) => {
    const result = new Date(currentDate);
    result.setDate(result.getDate() - minusDay);
    return result;
  };

  // Insight Variables
  const [avgDailyEnergyData, setAvgDailyEnergyData] = useState([]);
  const [numCheckInsData, setNumCheckInsData] = useState([]);
  const [wordCatecorizedExtractedData, setWordCatecorizedData] = useState([]);
  const [wordCloudExtractedData, setWordCloudData] = useState([]);

  // Navigator Variables
  const [allUser, setAllUser] = useState(false);
  const [allCompany, setAllCompany] = useState(false);

  // APIs
  // const API_URL = "http://34.218.252.245:5000/";

  // fetch json from API
  const [jsonObj, setJsonObj] = useState(null);

  const isSameDay = (firstDate, secondDate) =>
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate();

  const filterJsonByDates = (periodStart, periodEnd) => {
    let startPoint = 0;
    let endPoint = 0;
    for (let index = 0; index < dates.length; index += 1) {
      const currentTime = new Date(dates[index].date);
      if (isSameDay(currentTime, periodStart)) {
        startPoint = index;
      }
      if (isSameDay(currentTime, periodEnd)) {
        endPoint = index + 1;
        return {
          data: dates.slice(startPoint, endPoint)
        };
      }
    }
  };

  // use selectedDateFilter later in case we need to filter JSON by dates.
  const getJson = (periodStart, periodEnd) => {
    setJsonObj(null);
    // axios
    //   .get(API_URL, {
    //     params: {
    //       start_date: periodStart,
    //       end_date: periodEnd
    //     }
    //   })
    //   .then((value) => {
    //     setJsonObj(value.data);
    //     // console.log(value.data.data)
    //   })
    //   .catch((e) => console.log(e));
    setJsonObj(filterJsonByDates(periodStart, periodEnd));
  };
  // Custom JSON function
  const getCustomJson = (periodStart, periodEnd) => {
    setJsonObj(null);
    // axios
    //   .get(API_URL, {
    //     params: {
    //       start_date: periodStart,
    //       end_date: periodEnd
    //     }
    //   })
    //   .then((value) => {
    //     const responseData = value.data;
    //     responseData.isCustom = true;
    //     console.log(responseData);
    //     setJsonObj(responseData);
    //   })
    //   .catch((e) => console.log(e));
    const responseData = filterJsonByDates(periodStart, periodEnd);
    responseData.isCustom = true;
    setJsonObj(responseData);
  };
  // Calculate Total Checkins, Mindfulness and Reflections for a single date
  const calculateSummaryBarAttributeSingleDay = (singleDay) => {
    let checkinsNumber = 0;
    let mindfulnessNumber = 0;
    let reflectionsNumber = 0;
    const users = singleDay.engagedUsers;
    for (const user of users) {
      reflectionsNumber += user.reflections;
      mindfulnessNumber += user.mindfulness;
      checkinsNumber += user.checkins.length;
    }
    return {
      totalCheckins: checkinsNumber,
      totalReflections: reflectionsNumber,
      totalMindfulness: mindfulnessNumber
    };
  };
  // Calculate Total Checkins, Mindfulness and Reflections for a list of dates
  const calculateSummaryBarTotalAttributes = (prop) => {
    let checkinsNumber = 0;
    let mindfulnessNumber = 0;
    let reflectionsNumber = 0;
    for (const userData of prop) {
      const userDataAttribute = calculateSummaryBarAttributeSingleDay(userData);
      checkinsNumber += userDataAttribute.totalCheckins;
      reflectionsNumber += userDataAttribute.totalReflections;
      mindfulnessNumber += userDataAttribute.totalMindfulness;
    }
    return {
      totalCheckins: checkinsNumber,
      totalReflections: reflectionsNumber,
      totalMindfulness: mindfulnessNumber
    };
  };

  // Change Summary Panel
  const changeSummaryPanel = (prev, current) => {
    const prevAttr = calculateSummaryBarTotalAttributes(prev);
    const currentAttr = calculateSummaryBarTotalAttributes(current);
    setTotalCheckins(currentAttr.totalCheckins);
    setTotalMindfulness(currentAttr.totalMindfulness);
    setReflections(currentAttr.totalReflections);
    setCheckinsDiff(currentAttr.totalCheckins - prevAttr.totalCheckins);
    setMindfulnessDiff(
      currentAttr.totalMindfulness - prevAttr.totalMindfulness
    );
    setReflectionsDiff(
      currentAttr.totalReflections - prevAttr.totalReflections
    );
  };

  // Change Summary Panel (Custom)
  const changeSummaryPanelCustom = (prop) => {
    const attribute = calculateSummaryBarTotalAttributes(prop);
    setTotalCheckins(attribute.totalCheckins);
    setTotalMindfulness(attribute.totalMindfulness);
    setReflections(attribute.totalReflections);
    const firstDate = calculateSummaryBarAttributeSingleDay(prop[0]);
    const lastDate = calculateSummaryBarAttributeSingleDay(
      prop[prop.length - 1]
    );
    setCheckinsDiff(firstDate.totalCheckins - lastDate.totalCheckins);
    setMindfulnessDiff(firstDate.totalMindfulness - lastDate.totalMindfulness);
    setReflectionsDiff(firstDate.totalReflections - lastDate.totalReflections);
  };

  // ---------------------------functions for User Summary----------------------------

  // Given the Json object of a single day -> a list that comntains three maps:
  // [dayHashmapIsReturning, dayHashmapIsNew, dayHashmapUserType]
  function singleDayMap(singleDayObj) {
    const users = singleDayObj.engagedUsers;
    // console.log(users);
    const dayHashmapIsReturning = users.reduce((mapAccumulator, obj) => {
      mapAccumulator.set(obj.userId, obj.isReturning);
      return mapAccumulator;
    }, new Map());
    const dayHashmapIsNew = users.reduce((mapAccumulator, obj) => {
      mapAccumulator.set(obj.userId, obj.isNew);
      return mapAccumulator;
    }, new Map());
    const dayHashmapUserType = users.reduce((mapAccumulator, obj) => {
      mapAccumulator.set(obj.userId, obj.userType);
      return mapAccumulator;
    }, new Map());
    return [dayHashmapIsReturning, dayHashmapIsNew, dayHashmapUserType];
  }

  // given the map list for day 1 and the list of json objects of the rest -> a list that comntains three maps.
  // [dayHashmapIsReturning, dayHashmapIsNew, dayHashmapUserType]
  function rangeMap(firstMapList, secondToLastObjList) {
    let [
      dayHashmapIsReturning,
      dayHashmapIsNew,
      dayHashmapUserType
    ] = firstMapList;
    for (const obj of secondToLastObjList) {
      const mapList = singleDayMap(obj);
      const [isReturning, isNew, userType] = mapList;

      dayHashmapIsReturning = new Map([
        ...dayHashmapIsReturning,
        ...isReturning
      ]);
      dayHashmapIsNew = new Map([...dayHashmapIsNew, ...isNew]);
      dayHashmapUserType = new Map([...dayHashmapUserType, ...userType]);
    }
    return [dayHashmapIsReturning, dayHashmapIsNew, dayHashmapUserType];
  }

  // helper: count numbers from the three maps: [dayHashmapIsReturning, dayHashmapIsNew, dayHashmapUserType]
  // returns an object that holds the 6 variabbles.
  function countTotalsOffMap(userMapList) {
    const [
      dayHashmapIsReturning,
      dayHashmapIsNew,
      dayHashmapUserType
    ] = userMapList;
    const usersTotal = dayHashmapIsReturning.size;
    let usersNew = 0;
    let usersReturning = 0;
    let usersPaid = 0;
    let usersFree = 0;
    let usersEnterprise = 0;

    dayHashmapIsNew.forEach((value) => (value === 1 ? usersNew++ : usersNew));
    dayHashmapIsReturning.forEach((value) =>
      value === 1 ? usersReturning++ : usersReturning
    );
    dayHashmapUserType.forEach((value) => {
      if (value === 1) {
        usersPaid++;
      }
      if (value === 2) {
        usersFree++;
      }
      if (value === 3) {
        usersEnterprise++;
      }
    });
    return {
      usersTotal,
      usersNew,
      usersReturning,
      usersPaid,
      usersFree,
      usersEnterprise
    };
  }

  // helper: returns the difference of two count objects, as an object.
  function calculateDifference(past, current) {
    return {
      totalDiff: current.usersTotal - past.usersTotal,
      newDiff: current.usersNew - past.usersNew,
      returningDiff: current.usersReturning - past.usersReturning,
      paidDiff: current.usersPaid - past.usersPaid,
      freeDiff: current.usersFree - past.usersFree,
      enterpriseDiff: current.usersEnterprise - past.usersEnterprise
    };
  }

  // helper: given a time period -> returns the 6 user variables as an object
  function calculate6Variables(objList) {
    const startObj = objList[0];
    const secondToLastObjList = objList.slice(1, objList.length);
    const startMapList = singleDayMap(startObj);
    const totalMapList = rangeMap(startMapList, secondToLastObjList);
    return countTotalsOffMap(totalMapList);
  }

  // helper setStates
  // takes current and difference as input (the 6 user variables)
  function setStatesUserSummary(currentNumbers, differenceNumbers) {
    const {
      usersTotal,
      usersNew,
      usersReturning,
      usersPaid,
      usersFree,
      usersEnterprise
    } = currentNumbers;
    const {
      totalDiff,
      newDiff,
      returningDiff,
      paidDiff,
      freeDiff,
      enterpriseDiff
    } = differenceNumbers;
    setTotalUsers(usersTotal);
    setNewUsers(usersNew);
    setReturningUsers(usersReturning);
    setPaidUsers(usersPaid);
    setFreeUsers(usersFree);
    setEnterpriseUsers(usersEnterprise);

    setTotalUsersDiff(totalDiff);
    setNewUsersDiff(newDiff);
    setReturningUsersDiff(returningDiff);
    setPaidUsersDiff(paidDiff);
    setFreeUsersDiff(freeDiff);
    setEnterpriseUsersDiff(enterpriseDiff);
  }

  // if a pre-defined time period is selected.
  function displayUserSummary(pastObjList, currentObjList) {
    const current6Numbers = calculate6Variables(currentObjList);
    const past6Numbers = calculate6Variables(pastObjList);
    const difference = calculateDifference(past6Numbers, current6Numbers);
    setStatesUserSummary(current6Numbers, difference);
  }

  // if CUSTOM time range is selected.
  function displayUserSummaryCustom(entireObjList) {
    const big6Numbers = calculate6Variables(entireObjList);
    const start6Numbers = calculate6Variables(entireObjList.slice(0, 1));
    const end6Numbers = calculate6Variables(
      entireObjList.slice(1, entireObjList.length)
    );
    const difference = calculateDifference(start6Numbers, end6Numbers);
    setStatesUserSummary(big6Numbers, difference);
  }

  // ---------------------------functions for tables----------------------------

  // extract user Engagement Table data
  const getUserTableData = (data) => {
    const totUsers = [];
    for (let i = 0; i < data.length; i += 1) {
      const currentUsers = data[i].engagedUsers;
      for (let j = 0; j < currentUsers.length; j += 1) {
        const nowUser = currentUsers[j];
        totUsers.push(nowUser);
      }
    }

    const useRefl = {};
    const useMind = {};
    const useCheck = {};
    const useName = {};
    const useEmail = {};
    for (let i = 0; i < totUsers.length; i++) {
      const item = totUsers[i];
      if (useRefl[item.userId] === undefined) {
        useName[item.userId] = item.name;
        useRefl[item.userId] = 0;
        useMind[item.userId] = 0;
        useCheck[item.userId] = 0;
        useEmail[item.userId] = item.email;
      }
      useRefl[item.userId] += item.reflections;
      useMind[item.userId] += item.mindfulness;
      useCheck[item.userId] += item.checkins.length;
    }

    const mergedUsers = [];
    for (const i in useRefl) {
      if (Object.prototype.hasOwnProperty.call(useRefl, i)) {
        mergedUsers.push({
          name: useName[i],
          reflections: useRefl[i],
          mindfulness: useMind[i],
          checkins: useCheck[i],
          email: useEmail[i]
        });
      }
    }

    mergedUsers.sort((a, b) => {
      const totalb = b.reflections + b.checkins + b.mindfulness;
      const totala = a.reflections + a.checkins + a.mindfulness;
      return totalb - totala;
    });

    setUserEngTable(mergedUsers);
  };

  // extract company Engagement Table data
  const getCompTableData = (data) => {
    const totUsers = [];
    for (let i = 0; i < data.length; i += 1) {
      const currentUsers = data[i].engagedUsers;
      for (let j = 0; j < currentUsers.length; j += 1) {
        const nowUser = currentUsers[j];
        totUsers.push(nowUser);
      }
    }

    const useRefl = {};
    const useMind = {};
    const useCheck = {};
    const useName = {};
    for (let i = 0; i < totUsers.length; i++) {
      const item = totUsers[i];
      if (item.company !== undefined && useRefl[item.company] === undefined) {
        useName[item.company] = item.company;
        useRefl[item.company] = 0;
        useMind[item.company] = 0;
        useCheck[item.company] = 0;
      }
      if (item.company !== undefined) {
        useRefl[item.company] += item.reflections;
        useMind[item.company] += item.mindfulness;
        useCheck[item.company] += item.checkins.length;
      }
    }

    const mergedUsers = [];
    for (const i in useRefl) {
      if (Object.prototype.hasOwnProperty.call(useRefl, i)) {
        mergedUsers.push({
          name: useName[i],
          reflections: useRefl[i],
          mindfulness: useMind[i],
          checkins: useCheck[i]
        });
      }
    }

    mergedUsers.sort((a, b) => {
      const totalb = b.reflections + b.checkins + b.mindfulness;
      const totala = a.reflections + a.checkins + a.mindfulness;
      return totalb - totala;
    });

    setCompEngTable(mergedUsers);
  };

  // -------------------functions for average daily energy graph------------------------

  // function of extracting data for average daily energy graph
  const calculateAvgEnergy = (data) => {
    let totalEvg = 0;
    const dataLen = data.length;
    let count = 0;

    for (let i = 0; i < dataLen; i += 1) {
      const checkIns = data[i].checkins;
      let tempTotal = 0;

      for (let j = 0; j < checkIns.length; j += 1) {
        // all energy for the CheckIns
        tempTotal += checkIns[j].energyLevel;
        count += 1;
      }
      totalEvg += tempTotal;
    }
    return totalEvg / count;
  };

  // function of extracting data for average daily energy graph
  const avgDataExtractor = (data) => {
    const tempList = [];

    for (let i = 0; i < data.length; i++) {
      const tempdate = data[i].date.substring(0, 10);
      const userList = data[i].engagedUsers;
      const tempEnergy = calculateAvgEnergy(userList);
      const dictTemp = { date: tempdate, avgEnergy: tempEnergy };
      tempList.push(dictTemp);
    }

    return tempList;
  };

  // function of sorting the data by month
  const groupDateByYearMonth = (data) => {
    const allDataList = [];
    let tempDict = {};
    let tempList = [];
    let yearMonth = data[0].date.substring(0, 7);
    const lastYearMonth = data[data.length - 1].date.substring(0, 7);
    tempList.push(data[0]);

    for (let i = 1; i < data.length; i++) {
      const tempMonth = data[i].date.substring(0, 7);
      if (yearMonth === tempMonth) {
        tempList.push(data[i]);
        // make sure the last month is added
        if (lastYearMonth === tempMonth && i === data.length - 1) {
          tempDict = { time: yearMonth, datalist: tempList };
          allDataList.push(tempDict);
        }
      } else {
        tempDict = { time: yearMonth, datalist: tempList };
        allDataList.push(tempDict);
        yearMonth = data[i].date.substring(0, 7);
        tempList = [];
        tempList.push(data[i]);
      }
    }

    return allDataList;
  };

  // function of calculating the average daily energy by month
  const calculateAvgEnergyByYearMonth = (data) => {
    let totalEnergy = 0;
    let count = 0;

    for (let i = 0; i < data.length; i++) {
      const userList = data[i].engagedUsers;

      for (let j = 0; j < userList.length; j++) {
        const checkInsList = userList[j].checkins;

        for (let k = 0; k < checkInsList.length; k++) {
          const energyTemp = checkInsList[k].energyLevel;
          totalEnergy += energyTemp;
          count += 1;
        }
      }
    }
    return totalEnergy / count;
  };

  // function of extracting data for average daily energy graph by month
  const groupByYearExtractor = (data) => {
    const tempList = [];
    const groupedData = groupDateByYearMonth(data);

    for (let i = 0; i < groupedData.length; i++) {
      const dataList = groupedData[i].datalist;
      const averageEneregy = calculateAvgEnergyByYearMonth(dataList);

      const dictTemp = { date: groupedData[i].time, avgEnergy: averageEneregy };
      tempList.push(dictTemp);
    }
    return tempList;
  };

  // function to extract data for the pie graph
  const timeCheckInsDataExtractor = (data) => {
    let morningCheckIns = 0;
    let middayCheckIns = 0;
    let eveningCheckIns = 0;

    let morningTotal = 0;
    let middayTotal = 0;
    let eveningTotal = 0;

    for (let i = 0; i < 1; i += 1) {
      const userList = data[i].engagedUsers;
      for (let j = 0; j < userList.length; j += 1) {
        const checkinsList = userList[j].checkins;

        for (let k = 0; k < checkinsList.length; k += 1) {
          const tempTime = checkinsList[k].time;
          const tempEnergy = checkinsList[k].energyLevel;

          if (tempTime.substring(0, 2) < 12) {
            morningCheckIns += 1;
            morningTotal += tempEnergy;
          } else if (tempTime.substring(0, 2) < 18) {
            middayCheckIns += 1;
            middayTotal += tempEnergy;
          } else {
            eveningCheckIns += 1;
            eveningTotal += tempEnergy;
          }
        }
      }
    }

    return [
      {
        timeSlot: "Morning",
        numCheckIns: morningCheckIns,
        color: "#F97F5E",
        avgEnergy: (morningTotal / morningCheckIns).toFixed(1)
      },
      {
        timeSlot: "Mid-Day",
        numCheckIns: middayCheckIns,
        color: "#128988",
        avgEnergy: (middayTotal / middayCheckIns).toFixed(1)
      },
      {
        timeSlot: "Evening",
        numCheckIns: eveningCheckIns,
        color: "#5B6565",
        avgEnergy: (eveningTotal / eveningCheckIns).toFixed(1)
      }
    ];
  };

  // function to extract data for word categorized and word cloud
  const wordCategorizedDataExtractor = (data) => {
    const words = [];

    for (let i = 0; i < data.length; i++) {
      const userList = data[i].engagedUsers;
      for (let j = 0; j < userList.length; j++) {
        const { checkins } = userList[j];
        for (let k = 0; k < checkins.length; k++) {
          words.push(checkins[k].word);
        }
      }
    }

    const totalWords = words.length;
    const uniqueWords = [...new Set(words)];
    const wordCloudValue = [];
    const wordsPercentage = [];
    uniqueWords.forEach((crrWord) => {
      const value = words.filter((word) => word === crrWord).length;
      const percentage = parseFloat((value * 100) / totalWords).toFixed(2);
      wordCloudValue.push({ text: crrWord, value });
      wordsPercentage.push({ word: crrWord, percentage });
    });

    setWordCloudData(wordCloudValue);
    wordsPercentage.sort(
      (a, b) => parseFloat(a.percentage) - parseFloat(b.percentage)
    );
    return wordsPercentage;
  };

  // runs when user selects a dropdown filter item
  const fetchFilteredJson = (selectedDateFilter, periodStart, periodEnd) => {
    setFilter(selectedDateFilter); // set filter state
    getJson(periodStart, periodEnd);
  };

  // get custom json
  const fetchCustomJson = (selectedDateFilter, periodStart, periodEnd) => {
    setFilter(selectedDateFilter); // set filter state
    getCustomJson(periodStart, periodEnd);
  };

  const getToday = () =>
    fetchFilteredJson(
      `Today ${formatDate(initialDate)} - ${formatDate(
        calculateDate(initialDate, 1)
      )}`,
      calculateDate(initialDate, 1),
      initialDate
    );

  const getYesterday = () => {
    const yesterday = calculateDate(initialDate, 1);
    fetchFilteredJson(
      `Yesterday ${formatDate(yesterday)} - ${formatDate(
        calculateDate(yesterday, 1)
      )}`,
      calculateDate(yesterday, 1),
      yesterday
    );
  };

  const getDayString = (label, numberOfDays) => {
    const target = calculateDate(initialDate, numberOfDays * 2 - 1);
    fetchFilteredJson(
      `${label} ${formatDate(calculateDate(initialDate, numberOfDays - 1))}
       - ${formatDate(initialDate)}`,
      target,
      initialDate
    );
  };

  const getLastSundayDate = () => {
    const result = new Date(
      Date.UTC(
        initialDate.getFullYear(),
        initialDate.getMonth(),
        initialDate.getDate(),
        0
      )
    );
    result.setDate(result.getDate() - result.getDay());
    return result;
  };

  const sundayToToday = () => {
    const lastSunday = getLastSundayDate();
    fetchCustomJson(
      `Sun - Today ${formatDate(lastSunday)} - ${formatDate(initialDate)}`,
      lastSunday,
      initialDate
    );
  };

  const mondayToToday = () => {
    const thisMonday = getLastSundayDate();
    thisMonday.setDate(thisMonday.getDate() + 1);
    fetchCustomJson(
      `Mon - Today ${formatDate(thisMonday)} - ${formatDate(initialDate)}`,
      thisMonday,
      initialDate
    );
  };

  const saturdayToToday = () => {
    const lastSaturday = calculateDate(getLastSundayDate(), 1);
    fetchCustomJson(
      `Sat - Today ${formatDate(lastSaturday)} - ${formatDate(initialDate)}`,
      lastSaturday,
      initialDate
    );
  };

  const lastWeekMondayToSaturday = () => {
    const lastSunday = getLastSundayDate();
    const lastMonday = calculateDate(lastSunday, 6);
    const lastSaturday = calculateDate(lastSunday, 1);
    fetchCustomJson(
      `Mon - Sat ${formatDate(lastMonday)} - ${formatDate(lastSaturday)}`,
      lastMonday,
      lastSaturday
    );
  };

  const lastWeekMondayToSunday = () => {
    const lastSunday = getLastSundayDate();
    const lastMonday = calculateDate(lastSunday, 6);
    fetchCustomJson(
      `Mon - Sat ${formatDate(lastMonday)} - ${formatDate(lastSunday)}`,
      lastMonday,
      lastSunday
    );
  };

  const lastWeekSaturdayToFriday = () => {
    const lastSunday = getLastSundayDate();
    const lastLastSaturday = calculateDate(lastSunday, 8);
    const lastFriday = calculateDate(lastSunday, 2);
    fetchCustomJson(
      `Sat - Fri ${formatDate(lastLastSaturday)} - ${formatDate(lastFriday)}`,
      lastLastSaturday,
      lastFriday
    );
  };

  const getLastSevenDay = () => getDayString("Last 7 days", 7);

  const getLastTwentyEightDay = () => getDayString("Last 28 days", 28);

  const getLastThirtyDay = () => getDayString("Last 30 days", 30);

  const getLastNinetyDay = () => getDayString("Last 90 days", 90);

  const getLastTwelveMonth = () => {
    const numberOfDays = (() => {
      const target = new Date(initialDate);
      target.setMonth(target.getMonth() - 23);
      return (initialDate.getTime() - target.getTime()) / (1000 * 3600 * 24);
    })();
    return fetchFilteredJson(
      `Last 12 months ${formatDate(
        calculateDate(initialDate, numberOfDays / 2)
      )}
       - ${formatDate(initialDate)}`,
      calculateDate(initialDate, numberOfDays),
      initialDate
    );
  };

  const getLastCalendarYear = () => {
    const lastYear = initialDate.getFullYear() - 1;
    // Vancouver Time Zone
    const firstDate = new Date(lastYear, 0, 1);
    const lastDate = new Date(lastYear, 11, 31);
    return fetchCustomJson(
      `Last Calendar Year ${formatDate(firstDate)} - ${formatDate(lastDate)}`,
      firstDate,
      lastDate
    );
  };

  const getThisYear = () => {
    const firstDate = new Date(initialDate.getFullYear(), 0, 1);
    return fetchCustomJson(
      `This year(Jan - Today) ${formatDate(firstDate)} - ${formatDate(
        initialDate
      )}`,
      firstDate,
      initialDate
    );
  };

  const getCustomDate = () => {
    // close the dialog and update label
    setOpen(false);
    if (
      startDate &&
      endDate &&
      startDate <= initialDate &&
      endDate <= initialDate &&
      startDate !== endDate
    ) {
      if (startDate > endDate) {
        fetchCustomJson(`Custom ${end} - ${start}`, endDate, startDate);
      } else {
        fetchCustomJson(`Custom ${start} - ${end}`, startDate, endDate);
      }
    }
  };

  const setGraphs = (totalData) => {
    if (totalData.length > 60) {
      setAvgDailyEnergyData(groupByYearExtractor(totalData));
    } else {
      setAvgDailyEnergyData(avgDataExtractor(totalData));
    }
    setNumCheckInsData(timeCheckInsDataExtractor(totalData));
    setWordCatecorizedData(wordCategorizedDataExtractor(totalData));
  };

  // runs only once at the mounting phase.
  useEffect(() => {
    getToday();
  }, []);

  // This effect would execute whenever the json changes (Fetching API)
  // Put the variables you want to add to your components inside this function
  useEffect(() => {
    if (jsonObj !== null) {
      const totalData = jsonObj.data;
      // If the return json is custom date
      if (jsonObj.isCustom) {
        // Custom Summary Panel
        changeSummaryPanelCustom(totalData);
        // Custom User Summary
        displayUserSummaryCustom(totalData);
        // Custon user enagagement table
        getUserTableData(totalData);
        // Custom company engagment table
        getCompTableData(totalData);
        setGraphs(totalData);
      } else {
        const prevJson = totalData.slice(0, totalData.length / 2);
        const currentJson = totalData.slice(
          totalData.length / 2,
          totalData.length
        );
        // Summary Variables
        changeSummaryPanel(prevJson, currentJson);
        // User Summary
        displayUserSummary(prevJson, currentJson);
        // Most Engaged Users Variables
        // Company Engagement Variables
        // Most Engaged Users Variables
        getUserTableData(currentJson);
        // Company Engagement Variables
        getCompTableData(currentJson);
        // Insight Variables
        setGraphs(currentJson);
        // Variables Attach End
      }
    }
  }, [jsonObj]);

  // Loading Animation appears when data is not fetched
  if (jsonObj === null) {
    return <LoadingSpinner />;
  }

  if (allUser) {
    return (
      <MainContainer>
        <Container
          fluid
          className="d-flex flex-column justify-content-center"
          style={{ padding: "3% 5%" }}
        >
          <p style={{ fontSize: "20px" }}>
            <span
              role="button"
              tabIndex={0}
              className="btn mr-2"
              style={{
                cursor: "pointer",
                fontSize: "20px",
                padding: "0",
                margin: "0",
                verticalAlign: "baseline",
                color: "#069"
              }}
              onClick={() => setAllUser(false)}
              onKeyDown={() => setAllUser(false)}
            >
              Dashboard
            </span>
            &#62; Most Engaged Users
          </p>
          <div className="d-flex justify-content-between">
            <h3 style={{ fontFamily: "lato" }} className="pb-3">
              Most Engaged users
            </h3>
            <div>
              <h5
                className="mr-3"
                style={{
                  display: "inline-block",
                  color: "#838583",
                  fontSize: 12
                }}
              >
                Date Filter:
              </h5>

              <DateFilterBar
                lastWeekSaturdayToFriday={lastWeekSaturdayToFriday}
                setEndDate={setEndDate}
                start={start}
                handleFocusChange={handleFocusChange}
                getLastNinetyDay={getLastNinetyDay}
                focus={focus}
                onCloseModal={onCloseModal}
                startDate={startDate}
                getThisYear={getThisYear}
                getCustomDate={getCustomDate}
                open={open}
                filter={filter}
                getLastThirtyDay={getLastThirtyDay}
                mondayToToday={mondayToToday}
                lastWeekMondayToSunday={lastWeekMondayToSunday}
                lastWeekMondayToSaturday={lastWeekMondayToSaturday}
                endDate={endDate}
                getLastSevenDay={getLastSevenDay}
                setOpen={setOpen}
                onOpenModal={onOpenModal}
                getLastTwentyEightDay={getLastTwentyEightDay}
                MIN_DATE={MIN_DATE}
                saturdayToToday={saturdayToToday}
                getLastCalendarYear={getLastCalendarYear}
                getToday={getToday}
                getYesterday={getYesterday}
                setStartDate={setStartDate}
                getLastTwelveMonth={getLastTwelveMonth}
                sundayToToday={sundayToToday}
                end={end}
                MAX_DATE={MAX_DATE}
              />
            </div>
          </div>
          {/* Most Engaged Users Table */}
          <EngagementTable
            headers={userHeaders}
            tableType
            entities={userEngTable}
            clickFunction={() => {
              setAllUser(true);
            }}
            exp
            key="userEx"
          />
        </Container>
        ;
      </MainContainer>
    );
  }

  if (allCompany) {
    return (
      <MainContainer>
        <Container
          fluid
          className="d-flex flex-column justify-content-center"
          style={{ padding: "3% 5%" }}
        >
          <p style={{ fontSize: "20px" }}>
            <span
              role="button"
              tabIndex={0}
              className="btn mr-2"
              style={{
                cursor: "pointer",
                fontSize: "20px",
                padding: "0",
                margin: "0",
                verticalAlign: "baseline",
                color: "#069"
              }}
              onClick={() => setAllCompany(false)}
              onKeyDown={() => setAllCompany(false)}
            >
              Dashboard
            </span>
            &#62; Company Engagement
          </p>
          <div className="d-flex justify-content-between">
            <h3 style={{ fontFamily: "lato" }} className="pb-3">
              Company Engagement
            </h3>
            <div>
              <h5
                className="mr-3"
                style={{
                  display: "inline-block",
                  color: "#838583",
                  fontSize: 12
                }}
              >
                Date Filter:
              </h5>

              <DateFilterBar
                lastWeekSaturdayToFriday={lastWeekSaturdayToFriday}
                setEndDate={setEndDate}
                start={start}
                handleFocusChange={handleFocusChange}
                getLastNinetyDay={getLastNinetyDay}
                focus={focus}
                onCloseModal={onCloseModal}
                startDate={startDate}
                getThisYear={getThisYear}
                getCustomDate={getCustomDate}
                open={open}
                filter={filter}
                getLastThirtyDay={getLastThirtyDay}
                mondayToToday={mondayToToday}
                lastWeekMondayToSunday={lastWeekMondayToSunday}
                lastWeekMondayToSaturday={lastWeekMondayToSaturday}
                endDate={endDate}
                getLastSevenDay={getLastSevenDay}
                setOpen={setOpen}
                onOpenModal={onOpenModal}
                getLastTwentyEightDay={getLastTwentyEightDay}
                MIN_DATE={MIN_DATE}
                saturdayToToday={saturdayToToday}
                getLastCalendarYear={getLastCalendarYear}
                getToday={getToday}
                getYesterday={getYesterday}
                setStartDate={setStartDate}
                getLastTwelveMonth={getLastTwelveMonth}
                sundayToToday={sundayToToday}
                end={end}
                MAX_DATE={MAX_DATE}
              />
            </div>
          </div>
          {/* Most Engaged Users Table */}
          <EngagementTable
            headers={companyHeaders}
            tableType={false}
            entities={compEngTable}
            clickFunction={() => {
              setAllCompany(true);
            }}
            exp
            key="compEx"
          />
        </Container>
        ;
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <Container
        fluid
        className="d-flex flex-column justify-content-center"
        style={{ padding: "3% 5%" }}
      >
        {/* Summary Label */}
        <div className="d-flex mb-1">
          <h5
            className="mr-auto"
            style={{
              color: "#636463",
              fontWeight: "bold",
              fontSize: 14,
              marginTop: ".8em"
            }}
          >
            Summary
          </h5>

          <h5
            style={{
              display: "inline-block",
              color: "#838583",
              fontSize: 12,
              marginRight: "1%",
              marginTop: ".8em"
            }}
          >
            Date Filter:
          </h5>

          <DateFilterBar
            lastWeekSaturdayToFriday={lastWeekSaturdayToFriday}
            setEndDate={setEndDate}
            start={start}
            handleFocusChange={handleFocusChange}
            getLastNinetyDay={getLastNinetyDay}
            focus={focus}
            onCloseModal={onCloseModal}
            startDate={startDate}
            getThisYear={getThisYear}
            getCustomDate={getCustomDate}
            open={open}
            filter={filter}
            getLastThirtyDay={getLastThirtyDay}
            mondayToToday={mondayToToday}
            lastWeekMondayToSunday={lastWeekMondayToSunday}
            lastWeekMondayToSaturday={lastWeekMondayToSaturday}
            endDate={endDate}
            getLastSevenDay={getLastSevenDay}
            setOpen={setOpen}
            onOpenModal={onOpenModal}
            getLastTwentyEightDay={getLastTwentyEightDay}
            MIN_DATE={MIN_DATE}
            saturdayToToday={saturdayToToday}
            getLastCalendarYear={getLastCalendarYear}
            getToday={getToday}
            getYesterday={getYesterday}
            setStartDate={setStartDate}
            getLastTwelveMonth={getLastTwelveMonth}
            sundayToToday={sundayToToday}
            end={end}
            MAX_DATE={MAX_DATE}
          />
        </div>

        {/* Summary Bar */}
        <div className="mb-5">
          <SummaryPanel
            totalLeft={totalCheckins}
            differenceLeft={checkinsDiff}
            labelLeft="Check Ins"
            totalMiddle={totalMindfulness}
            differenceMiddle={mindfulnessDiff}
            labelMiddle="Mindfulness"
            totalRight={totalReflections}
            differenceRight={reflectionsDiff}
            labelRight="Reflections"
            colorCode="#418384"
          />
        </div>
        {/* User Summary Label */}
        <div className="mb-5">
          <h5
            style={{
              color: "#636463",
              fontWeight: "bold",
              fontSize: 14,
              marginBottom: "1em"
            }}
          >
            User Summary
          </h5>
          {/* User Summary Bars */}
          <SummaryPanel
            totalLeft={totalUsers}
            differenceLeft={totalUsersDiff}
            labelLeft="Total Users"
            totalMiddle={newUsers}
            differenceMiddle={newUsersDiff}
            labelMiddle="New Users"
            totalRight={returningUsers}
            differenceRight={returningUsersDiff}
            labelRight="Returning Users"
            colorCode="#636463"
          />
          <div style={{ margin: "1%" }} />
          <SummaryPanel
            totalLeft={paidUsers}
            differenceLeft={paidUsersDiff}
            labelLeft="Paid Users"
            totalMiddle={freeUsers}
            differenceMiddle={freeUsersDiff}
            labelMiddle="Free Users"
            totalRight={enterpriseUsers}
            differenceRight={enterpriseUsersDiff}
            labelRight="Enterprise Users"
            colorCode="#636463"
          />
        </div>

        {/* Most Engaged Users Label */}
        <div className="mb-5">
          <h5
            style={{
              fontFamily: "lato",
              color: "#636463",
              fontWeight: "bold",
              fontSize: 14,
              marginBottom: "1em"
            }}
          >
            Most Engaged users
          </h5>
          {/* Most Engaged Users Table */}
          <EngagementTable
            headers={userHeaders}
            tableType
            entities={userEngTable.slice(0, 10)}
            clickFunction={() => {
              setAllUser(true);
            }}
            exp={false}
            key="userD"
          />
        </div>

        {/* Company Engagement Label */}
        <div className="mb-5">
          <h5
            style={{
              fontFamily: "lato",
              color: "#636463",
              fontWeight: "bold",
              fontSize: 14,
              marginBottom: "1em"
            }}
          >
            Company Engagement
          </h5>
          {/* Company Engagement Table */}
          <EngagementTable
            headers={companyHeaders}
            tableType={false}
            entities={compEngTable.slice(0, 10)}
            clickFunction={() => {
              setAllCompany(true);
            }}
            exp={false}
            key="compD"
          />
        </div>

        {/* Insight Bars */}
        <div className="mb-4">
          <h5
            style={{
              color: "#636463",
              fontWeight: "bold",
              fontSize: 14,
              marginBottom: "1em"
            }}
          >
            Insights
          </h5>

          <CardDeck className="my-1">
            <AvgDailyEnergyBarCard data={avgDailyEnergyData} />
            <TimeCheckInsPieCard data={numCheckInsData} />
          </CardDeck>
          <br />

          <CardDeck className="my-1">
            <Card style={{ width: "100%" }}>
              <Card.Header
                style={{
                  color: "#838583",
                  fontSize: 13,
                  fontWeight: "bold",
                  zIndex: 0
                }}
              >
                Word Cloud
              </Card.Header>
              <Card.Body>
                <WordCloudGraph data={wordCloudExtractedData} />
              </Card.Body>
            </Card>

            <Card>
              <Card.Header
                style={{
                  color: "#838583",
                  fontSize: 13,
                  fontWeight: "bold",
                  zIndex: 0
                }}
              >
                Word Categorized
              </Card.Header>
              <Card.Body>
                <WordCatBarGraph data={wordCatecorizedExtractedData} />
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      </Container>
    </MainContainer>
  );
};

export default Dashboard;
