import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 52px);
  background-color: #f2f2f2;
  padding-top: 20px;
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      width: calc(100vw - 10rem);
      margin-left: 10rem;
    `}
  transition: 350ms;
  overflow-y: scroll;
`;
