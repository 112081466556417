import { useQuery, useMutation } from "@apollo/client";

import userGraph from "../../graphql-queries/user";
import { getWeatherFromAPI } from "../../weather";

export function useUpdateFirstName() {
  const [updateUser] = useMutation(userGraph.UPDATE_USER);

  const updateName = (newName) => {
    updateUser({
      variables: { request: { name: newName } }
    });
  };
  return { updateName };
}

export function useUser(timezone) {
  const { loading, error, data } = useQuery(userGraph.GET_USER, {
    variables: { request: { timezone } },
    fetchPolicy: "cache-and-network"
  });

  return { loading, data, error };
}

export async function SaveCheckin(
  mutation,
  createWeather,
  { checkin, latitude, longitude, timezone }
) {
  let weatherId;

  if (!("weather" in checkin)) {
    const weather = await getWeatherFromAPI(latitude, longitude);
    if (weather) {
      const newWeather = {
        variables: {
          request: weather
        }
      };
      const resultWeather = await createWeather(newWeather);
      weatherId = resultWeather.data.addWeatherInfo.id;
    }
  }

  const newCheckin = {
    variables: {
      request: checkin,
      timezone,
      weather: weatherId || null
    }
  };
  console.info({ newCheckin });
  const result = await mutation(newCheckin);
  console.info({ result });

  if ("data" in result) {
    const data = result.data.updateCheckIn
      ? result.data.updateCheckIn
      : result.data.addCheckIn;
    return {
      data,
      mutation: result.data.updateCheckIn ? "update" : "add"
    };
  }

  return {
    error: result
  };
}
