import styled from "styled-components";

export const Container = styled.div`
  color: #636463;
  font-family: Helvetica;
  font-size: 1.4em;
  background-color: #ffffff;
  box-shadow: 0px 2px 0px #fafafa;
  border-color: #fafafa;
  z-index: 1;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  margin-top: 0;
  color: #636463;
  font-family: Helvetica;
  font-size: 1.4em;
  text-align: center !important;
  word-wrap: break-word;
  font-weight: 400;
  line-height: 1.5;
`;

export const PreviousImage = styled.img`
  width: 3%;
  position: absolute;
  left: 35px;
  opacity: 0.5;
  transition: 350ms;

  :hover {
    opacity: 1;
  }
`;
