import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import TimeCheckInsPieGraph from "../TimeCheckInsPieGraph/index";

const TimeCheckInsPieCard = ({ data }) => (
  <Card style={{ width: "100%" }}>
    <Card.Header
      style={{ color: "#838583", fontSize: 13, fontWeight: "bold", zIndex: 0 }}
    >
      Time of check Ins
    </Card.Header>
    <Card.Body className="d-flex align-items-center">
      <TimeCheckInsPieGraph data={data} />
    </Card.Body>
  </Card>
);

TimeCheckInsPieCard.propTypes = {
  data: PropTypes.array
};

TimeCheckInsPieCard.defaultProps = {
  data: []
};

export default TimeCheckInsPieCard;
