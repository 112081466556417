import React from "react";
import PropTypes from "prop-types";

import { Container, Title, PreviousImage } from "./styles";

import arrowLeftSVG from "../../assets/svg/arrowLeft.svg";

const CardHeader = ({ title, goPrevious }) => (
  <Container>
    {goPrevious && (
      <PreviousImage src={arrowLeftSVG} alt="<" onClick={goPrevious} />
    )}
    <Title>{title}</Title>
  </Container>
);

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  goPrevious: PropTypes.func.isRequired
};

export default CardHeader;
