import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import wordClassificationsJSON from "./word-classifications.json"; // please, replace for a external served one to avoid extra download
import { ReactComponent as SmallArrowSVG } from "../../assets/svg/smallArrowDown.svg";
import {
  Wrapper,
  Title,
  TitleSection,
  CloseButton,
  WrappedSectionHeader,
  WrappedSection,
  SectionTitle,
  SubSectionsWrapper,
  WrappedSectionWordList,
  EploredWord
} from "./style";

const WordExplorer = ({ onSelect }) => {
  const [wrapperOpen, setWrapperOpen] = useState(false);
  const [sections, setSections] = useState([]);
  const [sectionsOpenIndex, setSectionsOpenIndex] = useState([]);

  useEffect(() => {
    if (wordClassificationsJSON) {
      const newSections = [];
      const newSectionsOpenIndex = [];
      for (const key of Object.keys(wordClassificationsJSON)) {
        const words = [key];
        for (const subKey of Object.keys(wordClassificationsJSON[key])) {
          words.push(subKey);
          if (!Array.isArray(wordClassificationsJSON[key][subKey])) {
            wordClassificationsJSON[key][subKey] = [
              wordClassificationsJSON[key][subKey]
            ];
          }
          for (const word of wordClassificationsJSON[key][subKey]) {
            words.push(word);
          }
        }
        newSections.push({ name: key, words: words.sort() });
        newSectionsOpenIndex.push(false);
      }
      setSectionsOpenIndex(newSectionsOpenIndex);
      setSections(newSections);
    }
  }, []);

  return (
    <Wrapper>
      <TitleSection
        onClick={() => {
          setWrapperOpen(true);
        }}
        wrapperOpen={wrapperOpen}
      >
        <Title wrapperOpen={wrapperOpen}>Explore Words</Title>
        <CloseButton
          wrapperOpen={wrapperOpen}
          onClick={(e) => {
            e.stopPropagation();
            setWrapperOpen(false);
          }}
        >
          x
        </CloseButton>
      </TitleSection>
      <SubSectionsWrapper wrapperOpen={wrapperOpen}>
        {sections.map((section, index) => (
          <WrappedSection key={`${section.name}index`}>
            <WrappedSectionHeader
              onClick={() => {
                const newSectionsOpenIndex = [...sectionsOpenIndex];
                newSectionsOpenIndex[index] = !sectionsOpenIndex[index];
                setSectionsOpenIndex(newSectionsOpenIndex);
              }}
            >
              <SectionTitle>{section.name}</SectionTitle>
              <SmallArrowSVG style={{ width: "0.5rem", marginRight: "1rem" }} />
            </WrappedSectionHeader>
            <WrappedSectionWordList
              sectionsOpenIndex={sectionsOpenIndex}
              index={index}
            >
              {section.words.map((word, wordIndex) => (
                <EploredWord
                  onClick={() => {
                    setWrapperOpen(false);
                    onSelect(word);
                  }}
                  key={`${section.name}-word-${wordIndex}`}
                >
                  {word}
                </EploredWord>
              ))}
            </WrappedSectionWordList>
          </WrappedSection>
        ))}
      </SubSectionsWrapper>
    </Wrapper>
  );
};

WordExplorer.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default WordExplorer;
