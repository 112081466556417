import React, { useState } from "react";
import PropTypes from "prop-types";

import steps from "./steps";

import { WizardWrapper, ContainerCard } from "./style";

const Checkin = ({ goTo }) => {
  const [wizardStep, setWizardStep] = useState(0);
  const [checkinData, setCheckinData] = useState({});

  const goNext = () => {
    if (wizardStep < steps.length - 1) {
      setWizardStep((prevState) => prevState + 1);
    }
  };
  const goPrevious = () => {
    if (wizardStep > 0) {
      setWizardStep((prevState) => prevState - 1);
    }
  };

  const Component = steps[wizardStep];

  return (
    <ContainerCard>
      <WizardWrapper>
        {Component && (
          <Component
            checkinData={checkinData}
            setCheckinData={setCheckinData}
            goNext={goNext}
            goTo={goTo}
            goPrevious={goPrevious}
            wizardStep={wizardStep}
            stepSize={steps.length}
          />
        )}
      </WizardWrapper>
    </ContainerCard>
  );
};

Checkin.propTypes = {
  goTo: PropTypes.func.isRequired
};

export default Checkin;
