import styled from "styled-components";

export const SpinnerWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const SpinnerIMG = styled.img`
  width: 15vh;
  margin-bottom: 15px;
`;

export const SpinnerText = styled.span`
  color: #51a3a4;
`;
