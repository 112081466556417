import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  user-select: none;
`;

export const Title = styled.span`
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;

  transition: all 350ms ease-in-out;
  margin-left: 0px;
  ${({ wrapperOpen }) =>
    wrapperOpen &&
    css`
      margin-left: 1rem;
    `}
`;

export const TitleSection = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  color: #3a8484;
  text-align: left;
  transition: 350ms;
  ${({ wrapperOpen }) =>
    wrapperOpen &&
    css`
      color: white;
      font-weight: 500;
      background-color: #3a8484;
    `}
`;

export const CloseButton = styled.div`
  color: white;
  padding: 10px 0 10px 30px;
  float: right;
  transition: 350ms;
  :hover {
    color: black;
  }
  margin-right: 1rem;

  cursor: pointer;

  -webkit-writing-mode: horizontal-tb !important;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  -webkit-font-smoothing: antialiased;
  user-select: none;

  display: none;

  ${({ wrapperOpen }) =>
    wrapperOpen &&
    css`
      display: flex;
    `}
`;

export const WrappedSectionHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  margin-left: 0px;
  padding: 10px 0 10px 30px;
  text-align: left;

  color: #636463;
  :hover {
    color: #51a3a4;
  }
  user-select: none;
`;
export const WrappedSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #ced4da;
`;

export const SectionTitle = styled.div`
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  margin-left: 0px;
  text-align: left;
`;

export const SubSectionsWrapper = styled.div`
  height: 0px;
  transition: all 200ms ease-in-out;
  overflow: hidden;
  ${({ wrapperOpen }) =>
    wrapperOpen &&
    css`
      height: 100%;
    `}
`;

export const WrappedSectionWordList = styled.div`
  width: 100%;
  height: 0px;
  overflow: hidden;
  padding: 0px;
  ${({ sectionsOpenIndex, index }) =>
    sectionsOpenIndex[index] &&
    css`
      transition: 350ms;
      height: 100%;
      padding: 10px 0 10px 30px;
    `}
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const EploredWord = styled.span`
  font-weight: 400;
  transition: all 0.25s ease-in-out;
  :hover {
    color: #51a3a4;
  }
  cursor: pointer;
  margin: 10px;
`;
