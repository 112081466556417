import React from "react";
import ReactDOM from "react-dom";

import { Auth0Provider } from "@auth0/auth0-react";
import { ApolloProvider } from "@apollo/client/react";

import { getClient } from "./services/apollo-client";
import App from "./App";

import "./styles/root.css";
import "./styles/animations.css";
import "./styles/storybookDefaults.css";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const client = getClient();

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      audience={audience}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
