import React from "react";
import PropTypes from "prop-types";
import NumberLabelCard from "../NumberLabelCard/index";

const SummaryPanel = ({
  totalLeft,
  differenceLeft,
  labelLeft,
  totalMiddle,
  differenceMiddle,
  labelMiddle,
  totalRight,
  differenceRight,
  labelRight,
  colorCode
}) => (
  <div
    className="w-100 border rounded border-secondary d-flex justify-content-between"
    style={{
      overflow: "auto",
      padding: "3% 5% 2% 5%",
      backgroundColor: "#fff"
    }}
  >
    <NumberLabelCard
      totalNumber={totalLeft}
      differenceNumber={differenceLeft}
      label={labelLeft}
      colorCode={colorCode}
    />
    <NumberLabelCard
      totalNumber={totalMiddle}
      differenceNumber={differenceMiddle}
      label={labelMiddle}
      colorCode={colorCode}
    />
    <NumberLabelCard
      totalNumber={totalRight}
      differenceNumber={differenceRight}
      label={labelRight}
      colorCode={colorCode}
    />
  </div>
);

SummaryPanel.propTypes = {
  totalLeft: PropTypes.number.isRequired,
  differenceLeft: PropTypes.number.isRequired,
  labelLeft: PropTypes.string.isRequired,
  totalMiddle: PropTypes.number.isRequired,
  differenceMiddle: PropTypes.number.isRequired,
  labelMiddle: PropTypes.string.isRequired,
  totalRight: PropTypes.number.isRequired,
  differenceRight: PropTypes.number.isRequired,
  labelRight: PropTypes.string.isRequired,
  colorCode: PropTypes.string.isRequired
};

export default SummaryPanel;
