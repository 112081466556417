import React from "react";
import ReactWordcloud from "react-wordcloud";
import PropTypes from "prop-types";

const options = {
  fontSizes: [20, 40],
  rotations: 60,
  rotationAngles: [-25, 25],
  padding: 1
};

const WordCloudGraph = ({ data }) => (
  <ReactWordcloud words={data} options={options} />
);

WordCloudGraph.propTypes = {
  data: PropTypes.array
};

WordCloudGraph.defaultProps = {
  data: []
};

export default WordCloudGraph;
