import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { linearGradientDef } from "@nivo/core";

// eslint-disable-next-line react/prop-types
const AvgDailyEnergyBarGraph = ({ aveDailyEnergyBarData }) => (
  <ResponsiveBar
    data={aveDailyEnergyBarData}
    keys={["avgEnergy"]}
    indexBy="date"
    maxValue={10}
    margin={{ top: 10, right: 20, bottom: 65, left: 60 }}
    axisTop={null}
    axisRight={null}
    axisBottom={
      // eslint-disable-next-line react/prop-types
      aveDailyEnergyBarData.length <= 50
        ? {
            tickSize: 1,
            tickPadding: 5,
            tickRotation: -90,
            legendPosition: "middle",
            legendOffset: 32
          }
        : null
    }
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Energy Level",
      legendPosition: "middle",
      legendOffset: -30
    }}
    enableLabel={false}
    defs={[
      linearGradientDef("gradientA", [
        { offset: 0, color: "#f7da59", opacity: 100 },
        { offset: 100, color: "#f5903d", opacity: 80 }
      ])
    ]}
    fill={[{ match: { id: "avgEnergy" }, id: "gradientA" }]}
  />
);

export default AvgDailyEnergyBarGraph;
