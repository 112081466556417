import styled, { css } from "styled-components";

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.25rem;
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  margin-bottom: 30px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const CardTextContainer = styled.div`
  margin-bottom: auto !important;
  margin-top: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const CardTitle = styled.div`
  padding: 10px;
  color: #636463;
  margin-bottom: 0.75rem;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
`;
export const CardText = styled.p`
  padding: 10px;
  color: #636463;
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center !important;
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`;

export const NextButton = styled.div`
  background-color: #ea6944 !important;
  border-color: #ea6944 !important;
  width: 100%;
  padding: 12px 10px 12px 10px;
  border-collapse: separate !important;
  border-radius: 70px;
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  display: block;
  margin-top: 3em;
  box-shadow: 0px 5px 8px #dddddd;
  cursor: pointer;
  align-self: flex-end !important;

  :hover {
    color: #ffffff;
    background-color: #f59088 !important;
    border-color: #f59088 !important;
  }
`;

export const CallingText = styled.span`
  ${({ marginTop }) => css`
    width: 100%;
    align-items: center;
    text-align: left;
    font-size: 1rem;
    margin-top: ${marginTop};
  `}
`;

export const TextInputWrapper = styled.div`
  ${({ marginBottom }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: ${marginBottom || "initial"};
  `}
`;

export const PraseCharCount = styled.span`
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
`;
