export const saveOnSession = (key, item) => {
  sessionStorage.setItem(key, item);
};

export const removeFromSession = (key) => {
  sessionStorage.removeItem(key);
};

export const getFromSession = (key) => {
  sessionStorage.fetItem(key);
};
