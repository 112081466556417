import { InMemoryCache } from "@apollo/client";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";

export const createCache = () => {
  const cache = new InMemoryCache();

  persistCache({
    cache,
    storage: new LocalStorageWrapper(window.sessionStorage)
  });

  return cache;
};
