import React, { useState, useEffect } from "react";
import { Table, Dropdown as RDrop } from "react-bootstrap";
import "./style.css";
import PropTypes from "prop-types";
import back from "../../assets/svg/back.svg";
import forward from "../../assets/svg/forward.svg";
import backEnd from "../../assets/svg/backEnd.svg";
import forwardEnd from "../../assets/svg/forwardEnd.svg";

const EngagementTable = (props) => {
  const { headers, tableType, entities, clickFunction, exp } = props;

  const INITIAL_SIZE = 10;
  const [numRows, setRows] = useState(INITIAL_SIZE);
  const [users, setUsers] = useState(entities);
  const [page, setPage] = useState(1);
  const [expd, setExpd] = useState(true);

  useEffect(() => {
    if (exp === true && expd === true) {
      setRows(15);
      setExpd(false);
    }

    setUsers(entities.slice((page - 1) * numRows, page * numRows));
  }, [numRows, page, expd, entities]);

  const goNext = () => {
    const totalLength = entities.length;

    if (page < totalLength / numRows) {
      setPage((prevState) => prevState + 1);
    }
  };

  const goBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goFirst = () => {
    setPage(1);
  };

  const goLast = () => {
    const lastPage = Math.ceil(entities.length / numRows);
    setPage(lastPage);
  };

  const rowChange = (newRow) => {
    setPage(1);
    setRows(newRow);
  };

  // generate table and rows
  if (exp === true) {
    return (
      <div className="outerTable">
        <div
          className="contain"
          style={{ backgroundColor: "white", overflow: "auto" }}
          key={headers.size}
        >
          <Table style={{ fontFamily: "Lato" }}>
            <thead>
              <tr key="exrow">
                {headers.map((header, index) => (
                  <th key={index} style={{ padding: "12px" }}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((entity, index) => (
                <React.Fragment key={`${index}parentEx`}>
                  {tableType ? (
                    <tr key={`row${index}`}>
                      <td key={`name${index}`} style={{ columnWidth: "11em" }}>
                        {entity.name}
                      </td>
                      <td key={`email${index}`} style={{ columnWidth: "16em" }}>
                        {entity.email}
                      </td>
                      <td key={`check${index}`} style={{ columnWidth: "5em" }}>
                        {entity.checkins}
                      </td>
                      <td
                        key={`mindful${index}`}
                        style={{ columnWidth: "5em" }}
                      >
                        {entity.mindfulness}
                      </td>
                      <td key={`reflec${index}`} style={{ columnWidth: "5em" }}>
                        {entity.reflections}
                      </td>
                      <td key={`total${index}`} style={{ columnWidth: "5em" }}>
                        {entity.reflections +
                          entity.checkins +
                          entity.mindfulness}
                      </td>
                    </tr>
                  ) : (
                    <tr key={`row${index}`}>
                      <td key={`name${index}`} style={{ columnWidth: "28em" }}>
                        {entity.name}
                      </td>
                      <td key={`check${index}`} style={{ columnWidth: "5em" }}>
                        {entity.checkins}
                      </td>
                      <td
                        key={`mindful${index}`}
                        style={{ columnWidth: "5em" }}
                      >
                        {entity.mindfulness}
                      </td>
                      <td key={`reflec${index}`} style={{ columnWidth: "5em" }}>
                        {entity.reflections}
                      </td>
                      <td key={`total${index}`} style={{ columnWidth: "5em" }}>
                        {entity.reflections +
                          entity.checkins +
                          entity.mindfulness}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-end">
          <div className="droprows" style={{ paddingRight: "2%" }}>
            <RDrop>
              <RDrop.Toggle
                id="dropdown-basic"
                style={{
                  color: "#838583",
                  backgroundColor: "transparent",
                  borderColor: "transparent"
                }}
              >
                {`Rows per page:  ${numRows}`}
              </RDrop.Toggle>
              <RDrop.Menu>
                <RDrop.Item as="button" onClick={() => rowChange(10)}>
                  10
                </RDrop.Item>
                <RDrop.Item as="button" onClick={() => rowChange(15)}>
                  15
                </RDrop.Item>
                <RDrop.Item as="button" onClick={() => rowChange(20)}>
                  20
                </RDrop.Item>
                <RDrop.Item as="button" onClick={() => rowChange(25)}>
                  25
                </RDrop.Item>
                <RDrop.Item as="button" onClick={() => rowChange(30)}>
                  30
                </RDrop.Item>
                <RDrop.Item as="button" onClick={() => rowChange(50)}>
                  50
                </RDrop.Item>
              </RDrop.Menu>
            </RDrop>
            {/* </BootDown> */}
          </div>
          <div
            className="pagelabel"
            style={{ paddingRight: "2%", color: "#838583" }}
          >
            {page * numRows <= entities.length ? (
              <p>
                {`${(page - 1) * numRows + 1}-${page * numRows} of ${
                  entities.length
                }`}
              </p>
            ) : (
              <p>
                {`${(page - 1) * numRows + 1}-${entities.length} of ${
                  entities.length
                }`}
              </p>
            )}
          </div>
          <input
            type="image"
            alt="first"
            src={backEnd}
            onClick={() => goFirst()}
          />
          <input type="image" alt="back" src={back} onClick={() => goBack()} />
          <input
            type="image"
            alt="next"
            src={forward}
            onClick={() => goNext()}
          />
          <input
            type="image"
            alt="last"
            src={forwardEnd}
            onClick={() => goLast()}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="contain"
      style={{ backgroundColor: "white", overflow: "auto" }}
      key={headers.size}
    >
      <Table style={{ fontFamily: "Lato" }}>
        <thead>
          <tr key="nonrow">
            {headers.map((header, index) => (
              <th key={index} style={{ padding: "12px" }}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map((entity, index) => (
            <React.Fragment key={`${index}parentNonEx`}>
              {tableType ? (
                <tr key={`row${index}`}>
                  <td key={`name${index}`} style={{ columnWidth: "11em" }}>
                    {entity.name}
                  </td>
                  <td key={`email${index}`} style={{ columnWidth: "16em" }}>
                    {entity.email}
                  </td>
                  <td key={`check${index}`} style={{ columnWidth: "5em" }}>
                    {entity.checkins}
                  </td>
                  <td key={`mindful${index}`} style={{ columnWidth: "5em" }}>
                    {entity.mindfulness}
                  </td>
                  <td key={`reflec${index}`} style={{ columnWidth: "5em" }}>
                    {entity.reflections}
                  </td>
                  <td key={`total${index}`} style={{ columnWidth: "5em" }}>
                    {entity.reflections + entity.checkins + entity.mindfulness}
                  </td>
                </tr>
              ) : (
                <tr key={`row${index}`}>
                  <td key={`name${index}`} style={{ columnWidth: "28em" }}>
                    {entity.name}
                  </td>
                  <td key={`check${index}`} style={{ columnWidth: "5em" }}>
                    {entity.checkins}
                  </td>
                  <td key={`mindful${index}`} style={{ columnWidth: "5em" }}>
                    {entity.mindfulness}
                  </td>
                  <td key={`reflec${index}`} style={{ columnWidth: "5em" }}>
                    {entity.reflections}
                  </td>
                  <td key={`total${index}`} style={{ columnWidth: "5em" }}>
                    {entity.reflections + entity.checkins + entity.mindfulness}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <div className="center">
        <button
          type="button"
          className="btn"
          style={{ color: "#E26D4C", fontWeight: "bold" }}
          onClick={clickFunction}
        >
          View All
        </button>
      </div>
    </div>
  );
};

EngagementTable.propTypes = {
  headers: PropTypes.array.isRequired,
  tableType: PropTypes.bool.isRequired,
  entities: PropTypes.array.isRequired,
  clickFunction: PropTypes.func.isRequired,
  exp: PropTypes.bool.isRequired
};

export default EngagementTable;
