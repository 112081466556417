import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import PropTypes from "prop-types";

const WordCatBarGraph = ({ data }) => (
  <ResponsiveBar
    margin={{ left: 110, bottom: 50, right: 30 }}
    data={data}
    keys={["percentage"]}
    indexBy="word"
    layout="horizontal"
    axisBottom={{
      legend: "Percent (%)",
      legendPosition: "middle",
      legendOffset: 40
    }}
    colors={{ scheme: "dark2" }}
    labelTextColor="#f5f5f5"
    colorBy="index"
    theme={{
      fontSize: 14
    }}
    enableGridY={false}
  />
);

WordCatBarGraph.propTypes = {
  data: PropTypes.array
};

WordCatBarGraph.defaultProps = {
  data: []
};

export default WordCatBarGraph;
