import React, { useState } from "react";
import PropTypes from "prop-types";

import { StyledInput } from "./style";

const TextAreaInput = ({ onChange, defaultValue, placeholder }) => {
  const [value, setValue] = useState(defaultValue || "");

  const changeHandler = (e) => {
    let innerValue = e?.target?.value || "";
    if (innerValue.length > 250) {
      innerValue = innerValue.substring(0, 250);
    }
    setValue(innerValue);
    onChange(innerValue);
  };

  return (
    <StyledInput
      placeholder={placeholder}
      onChange={changeHandler}
      value={value}
    />
  );
};

TextAreaInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default TextAreaInput;
