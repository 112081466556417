import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import AvgDailyEnergyBarGraph from "../AvgDailyEnergyBarGraph/index";

function avgDailyEnergyHeader(data) {
  let totalDailyEngergy = 0;
  for (let i = 0; i < data.length; i++) {
    totalDailyEngergy += data[i].avgEnergy;
  }

  return totalDailyEngergy / data.length;
}

const AvgDailyEnergyBarCard = ({ data }) => (
  <Card style={{ width: "100%" }}>
    <Card.Header
      style={{ color: "#838583", fontSize: 13, fontWeight: "bold", zIndex: 0 }}
    >
      Average daily energy:
      <span style={{ color: "#128988" }}>
        {avgDailyEnergyHeader(data).toFixed(1)}
      </span>
    </Card.Header>
    <Card.Body className="d-flex align-items-center">
      <AvgDailyEnergyBarGraph aveDailyEnergyBarData={data} />
    </Card.Body>
  </Card>
);

AvgDailyEnergyBarCard.propTypes = {
  data: PropTypes.array
};

AvgDailyEnergyBarCard.defaultProps = {
  data: null
};

export default AvgDailyEnergyBarCard;
