import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowBalance } from "../../assets/svg/arrow-balance.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";

const NumberLabelCard = ({
  totalNumber,
  differenceNumber,
  label,
  colorCode
}) => {
  const colorArrow = (number) => {
    if (number === 0) {
      return <ArrowBalance style={{ height: "16px", width: "16px" }} />;
    }
    if (number > 0) {
      return <ArrowUp style={{ height: "16px", width: "16px" }} />;
    }
    return <ArrowDown style={{ height: "16px", width: "16px" }} />;
  };

  return (
    <div className="text-center m-0 w-100">
      <div
        className="mb-3"
        style={{
          position: "relative",
          right: "-3vh",
          marginLeft: "5vh",
          marginRight: "7vh"
        }}
      >
        <span
          style={{
            fontSize: "29px",
            fontWeight: "bold",
            marginRight: "6px",
            color: colorCode
          }}
        >
          {totalNumber}
        </span>
        {colorArrow(differenceNumber)}
        <span
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            marginLeft: "2px",
            color: "#636463"
          }}
        >
          {Math.abs(differenceNumber)}
        </span>
      </div>
      <div style={{ fontSize: "14px", fontWeight: "900" }}>{label}</div>
    </div>
  );
};

NumberLabelCard.propTypes = {
  totalNumber: PropTypes.number.isRequired,
  differenceNumber: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  colorCode: PropTypes.string.isRequired
};

export default NumberLabelCard;
