import React from "react";
import PropTypes from "prop-types";

import { useAuth0 } from "@auth0/auth0-react";

import CardHeader from "../../../../components/CardHeader";
import CheckInCard from "../../../../components/CheckInCard";
import CarrouselDots from "../../../../components/CarrouselDots";

import {
  StepContainer,
  CardBody,
  CardTextContainer,
  NextButton,
  CardTitle
} from "./style";

const CongratsStep = ({
  checkinData,
  goTo,
  goPrevious,
  wizardStep,
  stepSize
}) => {
  const {
    user: { name }
  } = useAuth0();

  return (
    <StepContainer>
      <CardHeader title="You've Cheched In" goPrevious={goPrevious} />
      <CardBody>
        <CardTextContainer>
          <CardTitle>Congrats {name}!</CardTitle>
          <CheckInCard
            number={checkinData.energyLevel || 0}
            word={checkinData.word || ""}
            phrase={checkinData.phrase || ""}
            weather={null}
          />
        </CardTextContainer>
      </CardBody>
      <NextButton onClick={() => goTo("history")}>
        View check in history
      </NextButton>
      <CarrouselDots currentStep={wizardStep} stepsSize={stepSize} />
    </StepContainer>
  );
};

CongratsStep.propTypes = {
  checkinData: PropTypes.shape({
    energyLevel: PropTypes.number,
    word: PropTypes.string,
    phrase: PropTypes.string
  }).isRequired,
  goTo: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
  wizardStep: PropTypes.number.isRequired,
  stepSize: PropTypes.number.isRequired
};

export default CongratsStep;
