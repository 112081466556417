import React from "react";
import PropTypes from "prop-types";

import CardHeader from "../../components/CardHeader";

import {
  ContainerCard,
  CardBody,
  HardText,
  SexyLine,
  SimpleText,
  LinkText
} from "./style";

const _404_ = ({ goTo }) => (
  <ContainerCard>
    <CardHeader title="Oops!" />
    <CardBody>
      <HardText>Sorry, we could not find that page.</HardText>
      <SimpleText>
        Are you trying to&nbsp;
        <LinkText
          onClick={() => {
            goTo("checkin");
          }}
        >
          check in
        </LinkText>
        &nbsp;?
      </SimpleText>
      <SimpleText>
        Are you trying to&nbsp;
        <LinkText
          onClick={() => {
            goTo("history");
          }}
        >
          see your check ins
        </LinkText>
        &nbsp;?
      </SimpleText>
      <SimpleText>
        Are you trying to&nbsp;
        <LinkText
          onClick={() => {
            goTo("invite");
          }}
        >
          invite a friend
        </LinkText>
        &nbsp;?
      </SimpleText>
      <SexyLine />
    </CardBody>
  </ContainerCard>
);

_404_.propTypes = {
  goTo: PropTypes.func.isRequired
};

export default _404_;
