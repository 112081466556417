import React from "react";
import PropTypes from "prop-types";

import { Container } from "./style";

const PageCanvasContainer = ({ children, isMenuOpen }) => (
  <Container isMenuOpen={isMenuOpen}>{children}</Container>
);

PageCanvasContainer.propTypes = {
  children: PropTypes.element.isRequired,
  isMenuOpen: PropTypes.bool.isRequired
};

export default PageCanvasContainer;
