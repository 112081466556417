import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import DefaultHeader from "./components/Header";
import DefaultFooter from "./components/Footer";
import PageCanvasContainer from "./components/PageCanvasContainer";
import LoadingSpinner from "./components/LoadingSpinner";
import { saveOnSession } from "./helpers/session";
import pages from "./pages";

const App = () => {
  const [activePageKey, setActivePageKey] = useState("dashboard");
  const [currentPage, setCurrentPage] = useState(pages.dashboard);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState();
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();

  const setTokenOnSession = async () => {
    try {
      const token = await getAccessTokenSilently();
      saveOnSession("CheckingIn.token", token);
    } catch (e) {
      console.error("Error on saving session token", e);
    }
  };

  useEffect(() => {
    setTokenOnSession();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated && !user) {
    return loginWithRedirect();
  }
  if (!userData) {
    // prevent infinite re-renders
    setUserData(user);
  }

  // go to named inline step;
  const goTo = (pageKey) => {
    if (pageKey in pages) {
      setActivePageKey(pageKey);
      setCurrentPage(pages[pageKey]);
    } else {
      setActivePageKey("404");
      setCurrentPage(pages["404"]);
    }
  };

  // clear store and go back to home step (0);
  const goHome = () => {
    setActivePageKey("dashboard");
    setCurrentPage(pages.dashboard);
  };

  // render the component used now
  const render = () => {
    const {
      page_component: Component,
      custom_header: HeaderComponent,
      custom_footer: FooterComponent
    } = currentPage;
    return (
      <>
        {HeaderComponent ? (
          <HeaderComponent goHome={goHome} />
        ) : (
          <DefaultHeader
            setIsMenuOpen={setIsMenuOpen}
            activePage={activePageKey}
            goHome={goHome}
            goTo={goTo}
          />
        )}
        <PageCanvasContainer isMenuOpen={isMenuOpen}>
          <Component goTo={goTo} goHome={goHome} />
        </PageCanvasContainer>
        {FooterComponent ? (
          <FooterComponent goHome={goHome} />
        ) : (
          <DefaultFooter goHome={goHome} />
        )}
      </>
    );
  };

  return render();
};

export default App;
