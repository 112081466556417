import styled, { css } from "styled-components";

export const SlideBody = styled.div`
  margin-top: 52px;
  overflow-y: auto;
  background-color: #204040;
  width: 10rem;
  height: calc(100vh - 52px);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  ${({ isOpen }) =>
    isOpen &&
    css`
      left: 0px;
    `}
  transition: 850ms;
  z-index: 4;
  transition: 350ms;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  list-style: none;
  height: 3.5em;
  text-decoration: 0;
  color: #ffffff;
  font-size: 0.8em;
  align-items: center;
  padding: 1.5em 1.4em;
  font-weight: 700;
  width: 100%;

  :hover {
    color: #51a3a4;
    text-decoration: none;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-left: 8px solid #418384;
      background-color: #2b5656;
      color: #51a3a4;
      padding-left: calc(1.5em - 8px);
    `}

  cursor: pointer;
`;
