import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { buildStyles } from "react-circular-progressbar";

import CardHeader from "../../../../components/CardHeader";
import CarrouselDots from "../../../../components/CarrouselDots";

import "../../../../styles/rangeSliderStyle.css";
import "../../../../styles/progressCircleStyle.css";

import {
  StepContainer,
  CardBody,
  CardTextContainer,
  CircleProgress,
  RangeSlider,
  RangeWrapper,
  NextButton,
  RangeSideText
} from "./style";

const EnergyLevelStep = ({
  checkinData,
  setCheckinData,
  goNext,
  goPrevious,
  wizardStep,
  stepSize
}) => {
  const [energyLevel, setEnergyLevel] = useState(
    checkinData && checkinData.energyLevel ? checkinData.energyLevel : 0
  );

  useEffect(() => {
    setCheckinData({ ...checkinData, energyLevel });
  }, [energyLevel]);

  return (
    <StepContainer>
      <CardHeader title="What's your number?" goPrevious={goPrevious} />
      <CardBody>
        <CardTextContainer>
          <CircleProgress
            styles={buildStyles({
              strokeLinecap: "butt",
              textSize: "35px"
            })}
            strokeWidth={18}
            counterClockwise
            value={energyLevel}
            text={`${energyLevel}`}
            maxValue={10}
            minValue={0}
          />
        </CardTextContainer>
        <div>Energy Level</div>
        <RangeWrapper>
          <RangeSideText>Low</RangeSideText>
          <RangeSlider
            min={0}
            max={10}
            step={1}
            onChange={setEnergyLevel}
            value={energyLevel}
            tooltip={false}
          />
          <RangeSideText>High</RangeSideText>
        </RangeWrapper>
      </CardBody>
      <NextButton onClick={goNext}>Next</NextButton>
      <CarrouselDots currentStep={wizardStep} stepsSize={stepSize} />
    </StepContainer>
  );
};

EnergyLevelStep.propTypes = {
  checkinData: PropTypes.shape({
    energyLevel: PropTypes.number
  }).isRequired,
  setCheckinData: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
  wizardStep: PropTypes.number.isRequired,
  stepSize: PropTypes.number.isRequired
};

export default EnergyLevelStep;
