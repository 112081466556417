import React from "react";
import PropTypes from "prop-types";
import { buildStyles } from "react-circular-progressbar";

import "../../styles/progressCircleStyle.css";
import {
  Container,
  CircleProgress,
  CircleSection,
  WordWeatherWrapper,
  Word,
  TimeLine,
  WeatherLine,
  WeatherImage,
  PhraseWrapper
} from "./style";

import cloudySRC from "../../assets/svg/weather/cloudy.svg";

const CheckInCard = ({ number, word, phrase }) => (
  <Container>
    <CircleSection>
      <CircleProgress
        styles={buildStyles({
          strokeLinecap: "butt",
          textSize: "35px"
        })}
        strokeWidth={18}
        counterClockwise
        value={number}
        text={`${number}`}
        maxValue={10}
        minValue={0}
      />
    </CircleSection>
    <WordWeatherWrapper>
      <Word>{word}</Word>
      <TimeLine>Today at 10:28 PM</TimeLine>
      <WeatherLine>
        <WeatherImage alt="" src={cloudySRC} /> Cloudy
      </WeatherLine>
    </WordWeatherWrapper>
    <PhraseWrapper>{phrase}</PhraseWrapper>
  </Container>
);

CheckInCard.propTypes = {
  number: PropTypes.number.isRequired,
  word: PropTypes.string.isRequired,
  phrase: PropTypes.string.isRequired
};

export default CheckInCard;
