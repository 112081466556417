import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useAuth0 } from "@auth0/auth0-react";

import CardHeader from "../../../../components/CardHeader";
import CarrouselDots from "../../../../components/CarrouselDots";
import TextInput from "../../../../components/TextInput";
import TextAreaInput from "../../../../components/TextAreaInput";
import WordExplorer from "../../../../components/WordExplorer";
import {
  StepContainer,
  CardBody,
  CardTextContainer,
  NextButton,
  CallingText,
  TextInputWrapper,
  PraseCharCount
} from "./style";

// hooks and queries
import {
  useAddCheckin,
  useUpdateCheckin
} from "../../../../services/mutations/checkin";
import {
  useUser,
  useUpdateFirstName,
  SaveCheckin
} from "../../../../services/mutations/user";
import { useCreateWeather } from "../../../../services/mutations/weather";

const WordStep = ({
  checkinData,
  setCheckinData,
  goNext,
  goPrevious,
  wizardStep,
  stepSize
}) => {
  const [word, setWord] = useState(
    checkinData?.word || checkinData?.feelingWord || ""
  );
  const [phrase, setPhrase] = useState(
    checkinData?.phrase || checkinData?.feelingContext || ""
  );
  const { user } = useAuth0();
  const [addCheckIn] = useAddCheckin();
  const [updateCheckIn] = useUpdateCheckin();
  const [{ time_zone: timezone, latitude, longitude }] = useState(
    user["https://web.checkingin.co/geoip"]
  );
  const [createWeather] = useCreateWeather();
  const query = useUser(timezone);
  const { updateName } = useUpdateFirstName();
  const inputRef = useRef();

  useEffect(() => {
    if (!query.loading && query.data) {
      try {
        if (!sessionStorage.getItem("CheckingIn.username")) {
          if (!query.data.user.name && user.name) {
            updateName(user.name);
          } else {
            user.name = query.data.user.name;
          }
        }
        sessionStorage.setItem("CheckingIn.username", user.name);
      } catch (err) {
        console.error("ERR: ", err);
      }
    }
  }, [query]);

  useEffect(() => {
    setCheckinData({ ...checkinData, word: word || "" });
  }, [word]);

  useEffect(() => {
    setCheckinData({ ...checkinData, phrase: phrase || "" });
  }, [phrase]);

  const handleWordSelect = (wordSelected) => {
    setWord(wordSelected);
    inputRef.current.value(wordSelected);
  };

  const handleCheckIn = async (e) => {
    e.preventDefault();

    const checkin = {
      energyLevel: checkinData.energyLevel,
      feelingWord: word,
      feelingContext: phrase
    };

    let mutation = addCheckIn;
    if ("id" in checkin) {
      checkin.id = checkinData.id;
      mutation = updateCheckIn;
    }

    const result = await SaveCheckin(mutation, createWeather, {
      checkin,
      latitude,
      longitude,
      timezone
    });

    if ("data" in result) {
      if (result.mutation === "update") return goNext();

      let firstWord = checkin.feelingWord;
      if (firstWord.length > 0) {
        // eslint-disable-next-line
        firstWord = firstWord.split(',')[0];
      }
      checkin.firstWord = firstWord;
      checkin.weather = result.data.weather;
      setCheckinData(() => checkin);
    }
    if ("error" in result) {
      console.error("err: ", result);
    } else {
      goNext();
    }
    return null;
  };

  return (
    <StepContainer>
      <CardHeader title="What's your word?" goPrevious={goPrevious} />
      <CardBody>
        <CardTextContainer>
          <CallingText marginTop="1rem">
            What word best describes how you&apos;re feeling right now?
          </CallingText>
          <TextInputWrapper marginBottom="2rem">
            <TextInput
              ref={inputRef}
              defaultValue={word}
              onChange={setWord}
              placeholder="Enter one word"
            />
          </TextInputWrapper>
          <WordExplorer onSelect={handleWordSelect} />

          <CallingText marginTop="3rem">
            Want to elaborate? (optional)
          </CallingText>
          <TextInputWrapper>
            <TextAreaInput
              placeholder="Why are you feeling that way?"
              onChange={setPhrase}
              defaultValue={phrase}
              rows={4}
            />
          </TextInputWrapper>
          <PraseCharCount>{phrase ? phrase.length : 0}/250</PraseCharCount>
        </CardTextContainer>
      </CardBody>
      <NextButton onClick={handleCheckIn}>Next</NextButton>
      <CarrouselDots currentStep={wizardStep} stepsSize={stepSize} />
    </StepContainer>
  );
};

WordStep.propTypes = {
  checkinData: PropTypes.shape({
    energyLevel: PropTypes.number,
    word: PropTypes.string,
    feelingWord: PropTypes.string,
    feelingContext: PropTypes.string,
    phrase: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  setCheckinData: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
  wizardStep: PropTypes.number.isRequired,
  stepSize: PropTypes.number.isRequired
};

export default WordStep;
