import React from "react";
import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";

function AvgEnergyForPie(timeSlot, data) {
  if (timeSlot === "Morning") {
    return data[0].avgEnergy;
  }
  if (timeSlot === "Mid-Day") {
    return data[1].avgEnergy;
  }
  return data[2].avgEnergy;
}

function getPieTotal(pieData) {
  let pieTotal = 0;
  for (let i = 0; i < pieData.length; i++) {
    pieTotal += pieData[i].numCheckIns;
  }

  return pieTotal;
}

const TimeCheckInsPieGraph = ({ data }) => (
  <ResponsivePie
    data={data}
    id="timeSlot"
    value="numCheckIns"
    margin={{ top: 50, right: 80, bottom: 80, left: 80 }}
    padAngle={1}
    cornerRadius={1}
    colors={{ datum: "data.color" }}
    arcLabel={(e) => {
      const tempPercent = (e.value / getPieTotal(data)) * 100;
      return `${tempPercent.toFixed(1)} %`;
    }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor="#faf5f5"
    arcLinkLabel={(e) =>
      `${e.value} Check Ins, Avg. Energy:${AvgEnergyForPie(e.id, data)}`
    }
    arcLinkLabelsTextOffset={7}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsOffset={-18}
    arcLinkLabelsDiagonalLength={22}
    arcLinkLabelsStraightLength={10}
    arcLinkLabelsColor={{ from: "color" }}
    legends={[
      {
        anchor: "bottom",
        direction: "row",
        justify: false,
        translateX: 0,
        translateY: 50,
        itemsSpacing: 0,
        itemWidth: 80,
        itemHeight: 10,
        itemTextColor: "#999",
        itemDirection: "left-to-right",
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: "circle",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000"
            }
          }
        ]
      }
    ]}
  />
);

TimeCheckInsPieGraph.propTypes = {
  data: PropTypes.array
};

TimeCheckInsPieGraph.defaultProps = {
  data: []
};

export default TimeCheckInsPieGraph;
