import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useAuth0 } from "@auth0/auth0-react";

import SlideMenu from "../SideMenu";

import {
  Container,
  SandwishButton,
  SandwishButtonImage,
  LogoImage,
  LogoWrapper,
  ProfileSelectorWrapper,
  ProfileDropDownPic
} from "./styles";

import sandwishSRC from "../../assets/svg/sandwish.svg";
import logoSRC from "../../assets/img/logo.png";

const Header = ({
  goTo,
  goHome,
  activePage,
  setIsMenuOpen: setGlobalIsMenuOpen
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { user, logout } = useAuth0();

  const openMenuHandler = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setGlobalIsMenuOpen(isMenuOpen);
  }, [isMenuOpen]);

  return (
    <>
      <Container>
        <SandwishButton onClick={openMenuHandler} src={sandwishSRC}>
          <SandwishButtonImage src={sandwishSRC} />
        </SandwishButton>
        <LogoWrapper onClick={goHome}>
          <LogoImage src={logoSRC} />
        </LogoWrapper>

        <ProfileSelectorWrapper>
          <ProfileDropDownPic
            items={[
              {
                icon:
                  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1604623/trophy.png",
                alt: "trophy_icon",
                title: "Achievements",
                action: () => {
                  goTo("achievements");
                }
              },
              {
                icon:
                  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1604623/team.png",
                alt: "team_icon",
                title: "Team",
                action: () => {
                  goTo("team");
                }
              },
              {
                icon:
                  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1604623/book.png",
                alt: "book_icon",
                title: "Logs",
                action: () => {
                  goTo("logs");
                }
              }
            ]}
            avatar={user.picture}
            username={user.name}
            company={user.company}
            logout={logout}
          />
        </ProfileSelectorWrapper>
      </Container>
      <SlideMenu activePage={activePage} isOpen={isMenuOpen} goTo={goTo} />
    </>
  );
};

Header.propTypes = {
  goTo: PropTypes.func.isRequired,
  goHome: PropTypes.func.isRequired,
  activePage: PropTypes.string.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired
};

export default Header;
