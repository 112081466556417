import styled from "styled-components";

export const ChatButton = styled.div`
  background: rgb(65, 140, 147);
  border-radius: 0.3125rem;
  outline: none;
  display: flex;
  position: fixed;
  height: 56px;
  width: 56px;
  cursor: pointer;
  box-shadow: 0 0 8px 4px rgb(0 0 0 / 16%);
  right: 36px;
  bottom: 36px;
  border: none;
  padding: 0;
  transform: translateZ(0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;

  :hover {
    background-color: rgb(41, 91, 95);
  }
`;

export const ButtonIconImage = styled.img`
  width: 25px;
  height: 23px;
`;
