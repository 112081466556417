import _404_ from "./_404_";
import Checkin from "./Checkin";
import History from "./History";
import Dashboard from "./Dashboard";
import Groups from "./Groups";
import CheckIns from "./CheckIns";
import Users from "./Users";
import Mindfulness from "./Mindfulness";
import Reflections from "./Reflections";

const pages = {
  404: {
    menu_name: false,
    custom_header: false,
    page_component: _404_,
    custom_footer: false
  },
  checkin: {
    menu_name: "Check In",
    custom_header: false,
    page_component: Checkin,
    custom_footer: false
  },
  history: {
    menu_name: "My History",
    custom_header: false,
    page_component: History,
    custom_footer: false
  },
  dashboard: {
    menu_name: "Dashboard",
    custom_header: false,
    page_component: Dashboard,
    custom_footer: false
  },
  groups: {
    menu_name: "Groups",
    custom_header: false,
    page_component: Groups,
    custom_footer: false
  },
  users: {
    menu_name: "Users",
    custom_header: false,
    page_component: Users,
    custom_footer: false
  },
  mindfulness: {
    menu_name: "Mindfulness",
    custom_header: false,
    page_component: Mindfulness,
    custom_footer: false
  },
  reflections: {
    menu_name: "Reflections",
    custom_header: false,
    page_component: Reflections,
    custom_footer: false
  },
  checkIns: {
    menu_name: "Check Ins",
    custom_header: false,
    page_component: CheckIns,
    custom_footer: false
  }
};
export default pages;
