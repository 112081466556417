import styled from "styled-components";
import { CircularProgressbar } from "react-circular-progressbar";

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);

  text-align: center;

  width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1024px;
  min-height: 10vh;
`;

export const CircleProgress = styled(CircularProgressbar)`
  width: 5rem;
  height: 5rem;
  max-width: 400px;
  max-width: 400px;
`;

export const CircleSection = styled.div`
  width: 20%;
`;

export const WordWeatherWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
`;

export const Word = styled.span`
  font-weight: bolder;
  line-height: 1.5;
  color: #212529;
  font-size: 1rem;
`;

export const TimeLine = styled.span`
  font-size: 80%;
  font-weight: 400;
  color: #636463;
`;

export const WeatherLine = styled.div`
  font-size: 80%;
  font-weight: 400;
  color: #636463;
`;

export const WeatherImage = styled.img`
  width: 16px;
  margin: 3px;
`;

export const PhraseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  text-align: justify;
  padding: 10px;
`;
