import React, {
  useState,
  useImperativeHandle,
  useRef,
  forwardRef
} from "react";
import PropTypes from "prop-types";

import { StyledInput } from "./style";

const TextInput = forwardRef(({ onChange, defaultValue, placeholder }, ref) => {
  const [value, setValue] = useState(defaultValue || "");

  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    value: (val) => {
      setValue(val);
    }
  }));

  const changeHandler = (e) => {
    const innerValue = e?.target?.value || "";
    setValue(innerValue);
    onChange(innerValue);
  };

  return (
    <StyledInput
      ref={inputRef}
      placeholder={placeholder}
      onChange={changeHandler}
      value={value}
    />
  );
});

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

TextInput.displayName = "TextInput";

export default TextInput;
