import React, { useState, useEffect } from "react";

import spinnerSRC from "../../assets/img/CK_Logo.png";
import { SpinnerWrapper, SpinnerIMG, SpinnerText } from "./style";

const LoadingSpinner = () => {
  const [customText, setCustomText] = useState("Beath in...");
  const [loop, setLoop] = useState();

  useEffect(() => {
    setLoop(
      setInterval(() => {
        setCustomText((prevState) =>
          prevState === "Beath in..." ? "Breath out..." : "Beath in..."
        );
      }, 2400)
    );

    return function cleanup() {
      clearInterval(loop);
    };
  }, [customText]);

  return (
    <SpinnerWrapper>
      <SpinnerIMG className="rotate-center" alt="Loading..." src={spinnerSRC} />
      <SpinnerText>{customText}</SpinnerText>
    </SpinnerWrapper>
  );
};

export default LoadingSpinner;
