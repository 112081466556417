import { HttpLink, ApolloLink, concat } from "@apollo/client";

export const createLink = () => {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: sessionStorage.getItem("CheckingIn.token") || ""
      }
    });

    return forward(operation);
  });

  return concat(authMiddleware, httpLink);
};
