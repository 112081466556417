import styled from "styled-components";
import AvatarDropDown from "../AvatarDropDown";

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1020;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #418384;
  padding: 0 0.5rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
`;

export const SandwishButton = styled.div`
  border-style: none;
  cursor: pointer;
  background-color: #418384;
  border-color: #418384;
  box-shadow: none;
  margin-right: 0.5rem !important;
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SandwishButtonImage = styled.img`
  width: 0.875em;
`;

export const LogoWrapper = styled.div`
  display: inline-block;
  color: #fff;
  font-size: 1.25rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  line-height: inherit;
  white-space: nowrap;
  transition: all 0.25s ease-in-out;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  width: 7rem;
  cursor: pointer;
`;

export const LogoImage = styled.img`
  display: inline-block;
  width: 100%;
`;

export const ProfileSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-left: auto;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
`;

export const ProfileDropDownPic = styled(AvatarDropDown)`
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-top: 8px;
  margin-bottom: 8px;
`;
