import { gql } from "@apollo/client";
import fragments from "../fragments";

const graph = {
  GET_CHECKINS: gql`
    query GetCheckIns {
      checkIns {
        id
        ...CheckInProperties
      }
    }
    ${fragments.checkIn}
  `,
  ADD_CHECKIN: gql`
    mutation AddCheckIn(
      $request: NewCheckInRequest!
      $timezone: String
      $weather: String
    ) {
      addCheckIn(checkIn: $request, timezone: $timezone, weather: $weather) {
        id
        ...CheckInProperties
      }
      unlockReflection(timezone: $timezone) {
        id
        unlockDate
      }
    }
    ${fragments.checkIn}
  `,
  UPDATE_CHECKIN: gql`
    mutation UpdateCheckIn($request: CheckInUpdateRequest!) {
      updateCheckIn(checkIn: $request) {
        id
        ...CheckInProperties
      }
    }
    ${fragments.checkIn}
  `,
  CREATE_WEATHER: gql`
    mutation AddWeatherInfo($request: CreateWeatherInfo) {
      addWeatherInfo(weatherinfo: $request) {
        id
      }
    }
  `,
  DELETE_CHECKIN: gql`
    mutation DeleteCheckIn($request: CheckInWhereUniqueInput!) {
      deleteCheckIn(where: $request) {
        id
      }
    }
  `,
  GET_SHAREDCHECKINS: gql`
    query Get($request: SharedCheckinsRequest!) {
      sharedWithMe(params: $request) {
        user {
          id
          name
        }
        checkIns {
          id
          ...CheckInProperties
        }
      }
    }
    ${fragments.checkIn}
  `
};

export default graph;
