import styled from "styled-components";

export const MainContainer = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > div {
    margin-bottom: 1.5rem;
  }
`;
