import { Divider, Dropdown } from "semantic-ui-react";
import { Modal } from "react-responsive-modal";
import { DateRangePickerCalendar } from "react-nice-dates";
import { enGB } from "date-fns/locale";
import React from "react";
import { instanceOf, func, bool, string } from "prop-types";
import "react-responsive-modal/styles.css";
import "./style.css";

const DateFilterBar = ({
  filter,
  getToday,
  getYesterday,
  sundayToToday,
  mondayToToday,
  saturdayToToday,
  lastWeekMondayToSaturday,
  lastWeekMondayToSunday,
  lastWeekSaturdayToFriday,
  getLastSevenDay,
  getLastTwentyEightDay,
  getLastThirtyDay,
  getLastNinetyDay,
  getLastTwelveMonth,
  getLastCalendarYear,
  getThisYear,
  getCustomDate,
  open,
  onCloseModal,
  start,
  end,
  focus,
  startDate,
  endDate,
  MAX_DATE,
  MIN_DATE,
  setStartDate,
  setEndDate,
  handleFocusChange,
  setOpen,
  onOpenModal
}) => (
  <Dropdown style={{ display: "inline" }} text={filter}>
    <Dropdown.Menu>
      <Dropdown.Item text="Today" onClick={getToday} />
      <Dropdown.Item text="Yesterday" onClick={getYesterday} />
      <Dropdown text="This week" pointing="right" className="link item">
        <Dropdown.Menu>
          <Dropdown.Item onClick={sundayToToday}>Sun - Today</Dropdown.Item>
          <Dropdown.Item onClick={mondayToToday}>Mon - Today</Dropdown.Item>
          <Dropdown.Item onClick={saturdayToToday}>Sat - Today</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown text="Last week" pointing="right" className="link item">
        <Dropdown.Menu>
          <Dropdown.Item onClick={lastWeekMondayToSaturday}>
            Mon - Sat
          </Dropdown.Item>
          <Dropdown.Item onClick={lastWeekMondayToSunday}>
            Mon - Sun
          </Dropdown.Item>
          <Dropdown.Item onClick={lastWeekSaturdayToFriday}>
            Sat - Fri
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown.Item text="Last 7 days" onClick={getLastSevenDay} />
      <Dropdown.Item text="Last 28 days" onClick={getLastTwentyEightDay} />
      <Dropdown.Item text="Last 30 days" onClick={getLastThirtyDay} />
      <Dropdown.Item text="Last 90 days" onClick={getLastNinetyDay} />
      <Dropdown.Item text="Last 12 months" onClick={getLastTwelveMonth} />
      <Dropdown.Item text="Last calendar year" onClick={getLastCalendarYear} />
      <Dropdown.Item text="This year(Jan - Today)" onClick={getThisYear} />

      <Dropdown.Divider />
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal"
        }}
      >
        <p
          style={{
            marginBottom: "15px",
            color: "#636463",
            fontWeight: "bold"
          }}
        >
          CUSTOM
        </p>
        <div style={{ color: "#636463" }}>
          <p style={{ fontWeight: "bold" }}>
            <span style={{ marginRight: "6%" }}>{start}</span>-{" "}
            <span style={{ marginLeft: "6%" }}>{end}</span>.
          </p>
          <p>
            Currently selecting:{" "}
            <span style={{ fontWeight: "bold" }}>{focus}</span>.
          </p>
          <DateRangePickerCalendar
            startDate={startDate}
            endDate={endDate}
            focus={focus}
            maximumDate={MAX_DATE}
            minimumDate={MIN_DATE}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            onFocusChange={handleFocusChange}
            locale={enGB}
          />
        </div>
        <Divider />
        <span
          style={{
            marginLeft: "55%",
            marginTop: "2%",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <button
            type="button"
            style={{
              marginRight: "15px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "#636463",
              fontWeight: "bold",
              fontSize: "18px",
              cursor: "pointer"
            }}
            onClick={() => {
              // Сlose the dialog
              setOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            style={{
              border: "none",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "#E26D4C",
              fontWeight: "bold",
              fontSize: "18px",
              cursor: "pointer"
            }}
            onClick={getCustomDate}
          >
            Apply
          </button>
        </span>
      </Modal>
      <Dropdown.Item text="Custom" onClick={onOpenModal} />
    </Dropdown.Menu>
  </Dropdown>
);

DateFilterBar.propTypes = {
  filter: string.isRequired,
  getToday: func.isRequired,
  getYesterday: func.isRequired,
  sundayToToday: func.isRequired,
  mondayToToday: func.isRequired,
  saturdayToToday: func.isRequired,
  lastWeekMondayToSaturday: func.isRequired,
  lastWeekMondayToSunday: func.isRequired,
  lastWeekSaturdayToFriday: func.isRequired,
  getLastSevenDay: func.isRequired,
  getLastTwentyEightDay: func.isRequired,
  getLastThirtyDay: func.isRequired,
  getLastNinetyDay: func.isRequired,
  getLastTwelveMonth: func.isRequired,
  getLastCalendarYear: func.isRequired,
  getThisYear: func.isRequired,
  getCustomDate: func.isRequired,
  open: bool.isRequired,
  onCloseModal: func.isRequired,
  start: string.isRequired,
  end: string.isRequired,
  focus: string.isRequired,
  startDate: instanceOf(Date).isRequired,
  endDate: instanceOf(Date).isRequired,
  MAX_DATE: instanceOf(Date).isRequired,
  MIN_DATE: instanceOf(Date).isRequired,
  setStartDate: func.isRequired,
  setEndDate: func.isRequired,
  handleFocusChange: func.isRequired,
  setOpen: func.isRequired,
  onOpenModal: func.isRequired
};

export default DateFilterBar;
