import React from "react";

import CheckInCard from "../../components/CheckInCard";

import { MainContainer } from "./style";

const History = () => (
  <MainContainer>
    <CheckInCard
      number={8}
      word="Acomplished"
      phrase=" I am feeling just Acomplished"
      weather={null}
    />
    <CheckInCard
      number={7}
      word="Happy"
      phrase=" I am feeling just Happy"
      weather={null}
    />
    <CheckInCard
      number={6}
      word="Thrilled"
      phrase=" I am feeling just Thrilled"
      weather={null}
    />
    <CheckInCard
      number={9}
      word="Fine"
      phrase=" I am feeling just fine"
      weather={null}
    />
    <CheckInCard
      number={10}
      word="Very Happy"
      phrase=" I am feeling just Very Happy"
      weather={null}
    />
    <CheckInCard
      number={6}
      word="Engaged"
      phrase=" I am feeling just Engaged"
      weather={null}
    />
  </MainContainer>
);

export default History;
