import React from "react";

import chatBaloonSRC from "../../assets/svg/chatBaloon.svg";
import { ChatButton, ButtonIconImage } from "./styles";

const Footer = () => (
  <ChatButton>
    <ButtonIconImage src={chatBaloonSRC} />
  </ChatButton>
);

export default Footer;
