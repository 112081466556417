import React from "react";
import PropTypes from "prop-types";

import { useAuth0 } from "@auth0/auth0-react";

import CardHeader from "../../../../components/CardHeader";
import CarrouselDots from "../../../../components/CarrouselDots";
import {
  StepContainer,
  CardBody,
  CardTextContainer,
  CardTitle,
  CardText,
  NextButton
} from "./style";

const WelcomeStep = ({ goNext, wizardStep, stepSize }) => {
  const { user } = useAuth0();

  return (
    <StepContainer>
      <CardHeader title="Welcome" />
      <CardBody>
        <CardTextContainer>
          <CardTitle>Hey {user && user.name ? user.name : "______"}!</CardTitle>
          <CardText>Be true to your self, what are you feeling?</CardText>
          <CardText>Let&apos;s walk you through your check in.</CardText>
        </CardTextContainer>
      </CardBody>
      <NextButton onClick={goNext}>Next</NextButton>
      <CarrouselDots currentStep={wizardStep} stepsSize={stepSize} />
    </StepContainer>
  );
};

WelcomeStep.propTypes = {
  goNext: PropTypes.func.isRequired,
  wizardStep: PropTypes.number.isRequired,
  stepSize: PropTypes.number.isRequired
};

export default WelcomeStep;
