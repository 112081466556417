import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const Initials = styled.span`
  cursor: pointer;
  background: #f9f9f9;
  border: 3px solid #e0e0e0;
  border-radius: 100%;
  color: #636463;
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: bold;
  line-height: 31px;
  padding: 0.2rem;
  text-align: center;
  text-decoration: none;
`;

export const DropDownIndicator = styled.span`
  color: white;
  margin-left: 10px;
  cursor: pointer;
`;

export const DropDownWrapper = styled.div`
  opacity: 0;
  visibility: hidden;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      transition: all 0.2s ease-in-out;
      visibility: visible;
    `}
`;

export const Menu = styled.ul`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  font-family: "Lato", sans-serif;
  list-style: none;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  position: absolute;
  right: 30px;
  width: 256px;
`;

export const MenuItem = styled.li`
  //no style changes? 0o
`;

export const MenuLink = styled.div`
  ${({ color }) => css`
    cursor: pointer;
    color: ${color || "#333"};
    display: flex;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 16px;
    text-decoration: none;

    div {
      margin: auto 10px;
    }

    :hover {
      background-color: #f5f5f5;
      color: #333;
    }
  `}
`;

export const MenuItemIcon = styled.img`
  width: 20;
  height: 20;
`;

export const MenuItemTitle = styled.div`
  margin: auto 10px;
`;

export const ProfileHighlight = styled.div`
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  margin-bottom: 6px;
  padding: 12px 16px;
`;

export const AvatarImage = styled.img`
  border-radius: 25px;
  height: 48px;
  object-fit: cover;
  width: 48px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 12px;
`;

export const ProfileName = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const ProfileFooter = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 4px;
`;

export const Footer = styled.div`
  border-top: 1px solid #e0e0e0;
  margin-top: 6px;
  padding-top: 6px;
`;
